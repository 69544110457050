import React, {useState, useEffect, useContext} from "react";

import { withTranslation } from "react-i18next";
import {Select} from "antd"
import BulkProductsEditorTable from "../BulkProductsEditorTable/BulkProductsEditorTable";
import BRAND_QUERY from "../../gql/queries/BRAND_QUERY";
import MAIN_CATEGORY_QUERY from "../../gql/queries/MAIN_CATEGORY_QUERY";
import client from "../../config/apollo/client";
import PRODUCTS_TABLE_QUERY from "../../gql/queries/PRODUCTS_TABLE_QUERY";
import {BulkProductEditorContext} from "../../logic/context/BulkProductEditorContext/BulkProductEditorContext";
import BulkParametersProductsEditorTable from "../BulkParametersProductsEditorTable/BulkParametersProductsEditorTable";
import GET_PARAMETERS_BY_CATEGORY from "../../gql/queries/GET_PARAMETERS_BY_CATEGORY";

const {Option} = Select
const BulkProductsEditorMain = ({t}) =>{
    const [brands, setBrands] = useState([]);
    const [brandFilter, setBrandFilter] = useState(false)
    const [categories, setCategories ] = useState([])
    const [categoryFilter, setCategoryFilter] = useState(false)
    const [statusFilter, setStatusFilter] = useState(false)
    const [tableData, setTableData] = useState([])
    const [selectedParametersTable, setSelectedParametersTable] = useState([])
    const {editProducts, setEditProducts, editCategory, setEditCategory, categoryParameters, setSelectedParameters, selectedParameters, setCategoryParameters, reloadMainTable, setReloadMainTable} = useContext(BulkProductEditorContext)
    useEffect(()=>{
        client
            .query({
                query: BRAND_QUERY,
            })
            .then((res) => {
                setBrands(res.data.general.brands);
            });
    }, [])

    useEffect(()=>{
        if(brandFilter){
            client.query({
            query: MAIN_CATEGORY_QUERY,
            variables: {
                brand: brandFilter,
            },
            }).then((res)=> {
                setCategories(res.data.general.categories.edges)
            })
            setCategoryFilter(false)
            setStatusFilter(false)
            setTableData([])
            setEditProducts([])
        }


    }, [brandFilter])

    useEffect(()=>{
        setStatusFilter(false)
        setTableData([])
        setEditProducts([])
    },[categoryFilter])

    useEffect(()=>{
        if(statusFilter|| statusFilter===""){
            client
                .query({
                    query: PRODUCTS_TABLE_QUERY,
                    fetchPolicy: "network-only",
                    variables: {
                        status: statusFilter,
                        categoryId: categoryFilter,
                        brand: brandFilter,
                    },
                })
                .then((res) => {
                    setTableData(res.data.general.products.edges.map((obj)=>obj.node));
                });
        }
        setEditProducts([])
        setSelectedParameters([])
        setSelectedParametersTable([])
    }, [statusFilter])

    useEffect(()=>{
        if(editCategory){
            client.query({
                query: GET_PARAMETERS_BY_CATEGORY,
                variables:{"id": editCategory}
            }).then(res=>{
                setCategoryParameters(res.data.general.categories.edges[0].node.parameters.edges)
            })
        }
    },[editCategory])

    useEffect(()=>{
        if(reloadMainTable){
            client
                .query({
                    query: PRODUCTS_TABLE_QUERY,
                    fetchPolicy: "network-only",
                    variables: {
                        status: statusFilter,
                        categoryId: categoryFilter,
                        brand: brandFilter,
                    },
                })
                .then((res) => {
                    setTableData(res.data.general.products.edges.map((obj)=>obj.node));
                    setReloadMainTable(false)
                });
        }
    },[reloadMainTable])

    const handleChangeSelectParameters = (event) =>{
        let tmp_selected_parameters = []
        categoryParameters.map(
            (parameter)=>{
                if(event.indexOf(parameter.node.id)>-1){
                    tmp_selected_parameters.push(parameter)
                }
            }
        )
        setSelectedParametersTable(event)
        setSelectedParameters(tmp_selected_parameters)
    }
    return (
        <div>
            <div>
                <Select style={{width: "200px"}} value={brandFilter}  onChange={(e)=> {setBrandFilter(e)}} placeholder="Wybierz markę">
                    {brands.map((obj, idx) => {
                        return (
                            <Select.Option value={obj[0]} key={idx}>
                                {obj[1]}
                            </Select.Option>
                        );
                    })}
                </Select>

                <Select placeholder="Wybierz kategorię produktu" disabled={!brandFilter} value={categoryFilter} onChange={(e)=> {
                    setCategoryFilter(e)
                    setEditCategory(e)}}
                        style={{width: "200px"}}>
                        {categories.map((obj) => {
                            return (
                                <Select.Option value={obj.node.id} key={obj.node.id}>
                                    {obj.node.name}
                                </Select.Option>
                            );
                        })}
                </Select>

                <Select placeholder="Wybierz status produktu" disabled={!categoryFilter} value={statusFilter} onChange={(e)=>setStatusFilter(e)} style={{width: "200px"}}>
                    <Option value="D">{t('before')}</Option>
                    <Option value="R">{t('ready')}</Option>
                    <Option value="U">{t('out')}</Option>
                    <Option value="P">{t('current')}</Option>
                    <Option value="H">{t('hide')}</Option>
                    <Option value="">{t('all')}</Option>
                    <Option value="A">{t('archival')}</Option>
                </Select>

                <Select
                    disabled={(statusFilter===false)}
                    mode="multiple"
                    style={{width:"50%"}}
                    value={selectedParametersTable}
                    allowClear={true}
                    placeholder="Wybierz parametry"
                    onChange={handleChangeSelectParameters}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {categoryParameters.map(
                        (item)=>{
                                if(item.node.bulkEditable){
                                    return <Option key={item.node.id} value={item.node.id}>{item.node.name}</Option>
                                }
                            }
                        )
                    }
                </Select>
            </div>
            <BulkProductsEditorTable tableData={tableData} />
            <BulkParametersProductsEditorTable />
        </div>
    )
}

export default withTranslation('products')(BulkProductsEditorMain);