import gql from 'graphql-tag';

const TREE_CATEGORY_QUERY = gql`query TreeCategory ($brand: String) {
    general {
        categories(isMain:true, brand: $brand) {
            edges{
                node{
                    id
                    name
                    isMain
                    children{
                        totalCount,
                        edges{
                          node{
                            id,
                            name,
                            children{
                              edges{
                                node{
                                  id,
                                  name
                                  children{
                                    edges{
                                      node{
                                        id,
                                        name,
                                        children{
                                          edges{
                                            node{
                                              id,
                                              name,
                                              children{
                                                edges{
                                                  node{
                                                    id,
                                                    name
                                                    children{
                                                      edges{
                                                        node{
                                                          id,
                                                          name
                                                          children{
                                                            edges{
                                                              node{
                                                                id,
                                                                name
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                    }
                }
            }
        }
        
    }
}
`;

export default TREE_CATEGORY_QUERY;