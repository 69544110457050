import React, {useContext, useEffect, useState} from 'react';
import {Icon, Menu} from 'antd';
import {NavLink, MenuItem, StyledMenu} from '../../../components/_styledComponents';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import Cookies from "universal-cookie";
import styled from 'styled-components';
import ProductFilterContext from "../../../logic/context/productFilter/ProductFilterContext";

const cookies = new Cookies();

const removeSlash = (str) => {
    if (!str) return null;
    return str.replace('/', '')
};

const StyledSubMenu = styled(Menu.SubMenu)`
     ${({theme}) => theme.mq.laptop`
      span {
         color: ${({theme, activepath}) => activepath && theme.color.green};
      }
  `
}
`;


const SideNavigation = ({t, location}) => {
    const {setFiltersContext} = useContext(ProductFilterContext);

    const [openKeys, setOpenKeys] = useState(['']);
    const [seletedTab, setSelectedTab] = useState(location.state && location.state.tab);
    const rootSubmenuKeys = ['products', 'employees', 'data-export', 'price-import', 'product-import'];
    const [inline, setInline] = useState(null);
    const [path, setPath] = useState(removeSlash(location.pathname));

    useEffect(() => {
        setPath(removeSlash(location.pathname))
    }, [location]);

    const handleResize = () => {
        const screenWidth = window.innerWidth;
        setInline(screenWidth < 1420)
    };


    const setStylesUl = () => {
        if (inline === false) {
            return (
                {
                    width: '250px',
                }
            )
        } else {
            return (
                {
                    height: '50px',
                    width: '100%',
                }
            )
        }
    };

    const setStylesDiv = () => {
        if (inline === false) {
            return (
                {
                    width: '250px',
                    display: 'inline-block'
                }
            )
        } else {
            return (
                {
                    height: '50px',
                }
            )
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize)
    }, []);


    useEffect(() => {
        if (location.pathname.indexOf('/product') !== -1 || location.pathname === '/') {
            if (!location.state) {
                setSelectedTab('before')
            }
               setOpenKeys(['sub1'])
        } else if (location.pathname.indexOf('employees') !== -1) {
            setOpenKeys(['employees'])

        } else if (location.pathname.indexOf('data-export') !== -1) {
            setOpenKeys(['data-export'])

        } else if (location.pathname.indexOf('price-import') !== -1) {
            setOpenKeys(['price-import'])

        } else if (location.pathname.indexOf('promotions') !== -1) {
            setOpenKeys(['promotions'])
        } else if (location.pathname.indexOf('product-import') !== -1) {
            setOpenKeys(['product-import'])
        }
    }, []);

    useEffect(() => {
        if (location.state) {
            setSelectedTab(location.state.tab)
        }
    }, [location]);

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys)
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
        }
    };

    const removeCookie = () => {
        const productCookie = cookies.get('currentProductId');
        if (productCookie) {
            cookies.remove('currentProductId', {path: '/'});
        }
        ;
    };

    const clearProductFilters = () => {
        setFiltersContext({clear:true})
    };

    return (
        <div style={setStylesDiv()}>
            <StyledMenu
                mode={inline ? 'horizontal' : 'inline'}
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                onClick={({key}) => setSelectedTab(key)}
                style={setStylesUl()}
            >
                <StyledSubMenu key="products"
                               activepath={path === 'products'? 'true': undefined}
                               title={
                                   <span>
                          <Icon type="ordered-list"/>
                          <span>{t('products')}</span>
                      </span>
                               }
                >
                    <MenuItem key="before">
                        <NavLink
                            to={{
                                pathname: '/products',
                                tab: 'before',
                                state: {tab: 'before'}
                            }}
                            onClick={clearProductFilters}
                            isActive={() => seletedTab === 'before'}
                        >
                            {t("before")}
                        </NavLink>
                    </MenuItem>
                    <MenuItem key="ready">
                        <NavLink
                            to={{
                                pathname: '/products',
                                tab: 'ready',
                                state: {tab: 'ready'}
                            }}
                            onClick={clearProductFilters}
                            isActive={() => seletedTab === 'ready'}
                        >
                            {t("ready")}
                        </NavLink>
                    </MenuItem>
                    <MenuItem key="removed">
                        <NavLink
                            to={{
                                pathname: '/products',
                                tab: 'removed',
                                state: {tab: 'removed'}
                            }}
                            onClick={clearProductFilters}
                            isActive={() => seletedTab === 'removed'}
                        >
                            {t("out")}
                        </NavLink>
                    </MenuItem>
                    <MenuItem key="on-market">
                        <NavLink
                            to={{
                                pathname: '/products',
                                tab: 'on-market',
                                state: {tab: 'on-market'}
                            }}
                            onClick={clearProductFilters}
                            isActive={() => seletedTab === 'on-market'}
                        >
                            {t("current")}
                        </NavLink>
                    </MenuItem>
                    <MenuItem key="hidden">
                        <NavLink
                            to={{
                                pathname: '/products',
                                tab: 'hidden',
                                state: {tab: 'hidden'}
                            }}
                            onClick={clearProductFilters}
                            isActive={() => seletedTab === 'hidden'}
                        >
                            {t("hide")}
                        </NavLink>
                    </MenuItem>
                    <MenuItem key="all">
                        <NavLink
                            to={{
                                pathname: '/products',
                                tab: 'all',
                                state: {tab: 'all'}
                            }}
                            onClick={clearProductFilters}
                            isActive={() => seletedTab === 'all'}
                        >
                            {t("all")}
                        </NavLink>
                    </MenuItem>
                         <MenuItem key="archive">
                        <NavLink
                            to={{
                                pathname: '/products',
                                tab: 'archive',
                                state: {tab: 'archive'}
                            }}
                            onClick={clearProductFilters}
                            isActive={() => seletedTab === 'archive'}
                        >
                            {t("archival")}
                        </NavLink>
                    </MenuItem>
                      <MenuItem key="prices">
                        <NavLink
                            to={{
                                pathname: '/prices',
                                tab: 'prices',
                                state: {tab: 'prices'}
                            }}
                            isActive={() => seletedTab === 'prices'}
                        >
                            {t('addValidDate')}
                        </NavLink>
                    </MenuItem>
                    <MenuItem key="add-product">
                        <NavLink to={{
                            pathname: '/add-product/step=0',
                            action: 'redirect'
                        }} onClick={removeCookie}>{t('add-product')}</NavLink>
                    </MenuItem>
                    <MenuItem key="bulk-products-editor">
                        <NavLink to={{
                            pathname: '/bulk-products-editor',
                            action: 'redirect'
                        }} onClick={removeCookie}>{t('mass-edit-products')}</NavLink>
                    </MenuItem>
                </StyledSubMenu>
               <StyledSubMenu
                   key="promotions"
                   activepath={path === 'promotions'? 'true': undefined}
                   title={
                       <span>
                          <Icon type="fire"/>
                          <span>{t('promotions')}</span>
                      </span>
                           }>
                 <MenuItem key='promotions'>
                        <NavLink
                            to={{
                                pathname: '/promotions',
                            }}
                        >
                           {t('promotions')}
                        </NavLink>
                    </MenuItem>
                   <MenuItem key='promoted-products'>
                        <NavLink
                            to={{
                                pathname: '/add-promoted-products',
                            }}
                        >
                           Promowane produkty
                        </NavLink>
                    </MenuItem>
                       <MenuItem key="add-promotion">
                        <NavLink to={{
                            pathname: '/add-promotion',
                            action: 'redirect',
                        }}>+ dodaj promocję</NavLink>
                    </MenuItem>
               </StyledSubMenu>
                <StyledSubMenu key="employees"
                               activepath={path === 'employees'? 'true': undefined}
                               title={
                                   <span>
                  <Icon type="user"/>
                  <span>{t('employees')}</span>
              </span>
                               }
                >
                    <MenuItem key='employees'>
                        <NavLink
                            to={{
                                pathname: '/employees',
                            }}
                        >
                            {t('employeesList')}
                        </NavLink>
                    </MenuItem>
                    <MenuItem key="6">
                        <NavLink
                            to={{
                                pathname: '/employees',
                                action: 'add',
                                state: {action: 'add'}
                            }}
                            isActive={() => false}
                        >
                            {t('addEmployee')}
                        </NavLink>
                    </MenuItem>
                </StyledSubMenu>
                <StyledSubMenu key="data-export"
                               activepath={path === 'data-export'? 'true': undefined}
                               title={
                                   <span>
                  <Icon type="database"/>
                  <span>{t('dataExport')}</span>
              </span>
                               }>
                    <MenuItem key='employees'>
                        <NavLink
                            to={{
                                pathname: '/data-export',
                            }}
                        >
                            {t('templates')}
                        </NavLink>
                    </MenuItem>
                    <MenuItem key="7">
                        <NavLink
                            to={{
                                pathname: '/data-export',
                                action: 'export',
                            }}
                            isActive={() => false}
                        >
                            {t('fileExport')}
                        </NavLink>
                    </MenuItem>
                    <MenuItem key="8">
                        <NavLink
                            to={{
                                pathname: '/data-export',
                                action: 'create',
                            }}
                            isActive={() => false}
                        >
                            {t('createTemplate')}
                        </NavLink>
                    </MenuItem>
                </StyledSubMenu>
                <StyledSubMenu key="price-import"
                               activepath={path === 'price-import'? true: undefined}
                               title={
                                   <span>
                  <Icon type="tags"/>
                  <span>{t('priceImport')}</span>
              </span>
                               }>
                    <MenuItem key='price-import'>
                        <NavLink
                            to={{
                                pathname: '/price-import',
                            }}
                        >
                            {t('importsHistory')}
                        </NavLink>
                    </MenuItem>
                    <MenuItem key="7">
                        <NavLink
                            to={{
                                pathname: '/price-import',
                                action: 'open-modal',
                                state: 'price-import'
                            }}
                            isActive={() => false}
                        >
                            {t('importPrice')}
                        </NavLink>
                    </MenuItem>
                </StyledSubMenu>
                 <StyledSubMenu key="product-import"
                               activepath={path === 'product-import'? true: undefined}
                               title={
                                   <span>
                  <Icon type="upload"/>
                  <span>{t('productImports')}</span>
              </span>
                               }>
                    <MenuItem key='price-import'>
                        <NavLink
                            to={{
                                pathname: '/product-import',
                            }}
                        >
                            {t('importProductsHistory')}
                        </NavLink>
                    </MenuItem>
                    <MenuItem key="7">
                        <NavLink
                            to={{
                                pathname: '/product-import',
                                action: 'open-modal',
                                state: 'product-import'
                            }}
                            isActive={() => false}
                        >
                            {t('importProduct')}
                        </NavLink>
                    </MenuItem>
                </StyledSubMenu>
                <MenuItem key="categories">
                    <NavLink to={{pathname: '/categories/V'}}>
                        <Icon type="bars" />{t('categoriesAndSubcategories')}
                    </NavLink>
                </MenuItem>
                <MenuItem key="product_selection_system">
                    <NavLink to={{pathname: '/product-selection-system'}}>
                        <Icon type="shop" />{t('productSelectionSystem')}</NavLink>
                </MenuItem>
            </StyledMenu>
        </div>
    );
};

export default withRouter(withTranslation('products')(SideNavigation));
