import React, {useState, useEffect} from "react";
import {withTranslation} from "react-i18next";

import {Upload, message, Button, Icon, Select} from "antd";

import {get, isNil} from "lodash";
import uppercaseFirstLetter from "../../../utils/uppercaseFirstLetter";
import formatFileName from "../../../utils/formatFileName";

import {
  UploadWrapper,
  StyledTitle,
} from "../../_styledComponents/CategoriesTable/DefaultCategoryImage";

import IMPORT_DEFAULT_CATEGORY_IMAGE_MUTATION from "../../../gql/mutations/IMPORT_DEFAULT_CATEGORY_IMAGE_MUTATION";
import DELETE_DEFAULT_CATEGORY_IMAGE_MUTATION from "../../../gql/mutations/DELETE_DEFAULT_CATEGORY_IMAGE_MUTATION";
import CATEGORY_DEFAULT_IMAGE_QUERY from "../../../gql/queries/CATEGORY_DEFAULT_IMAGE_QUERY";

import client from "../../../config/apollo/client";
import StyledGrid from "../../_styledComponents/StyledGrid";
import CategoriesTree from "../../CategoriesTree";


const DefaultCategory = ({t, showBrandSelect, onBrandChange, brand}) => {
  const [ fileList, setFileList ] = useState([]);

  const successMsg = t("msg-success");
  const errorMsg = t("msg-error");

  useEffect(() => {
    fetchDefaultImage();
  }, []);

  const createFileModel = (fileName, status, uid) => [
    {
      status,
      name: formatFileName(fileName),
      uid: uid || Date.now(),
    },
  ];

  const fetchDefaultImage = () => {
    client
      .query({
        query: CATEGORY_DEFAULT_IMAGE_QUERY,
      })
      .then((res) => {
        const defaultImagePath = get(res, "data.general.categoryDefaultImage");
        if (!isNil(defaultImagePath))
          setFileList(createFileModel(defaultImagePath, "done"));
      })
      .catch(() => message.error(t("default-image-download-error")));
  };

  const uploadFile = ({file}) => {
    client
      .mutate({
        mutation: IMPORT_DEFAULT_CATEGORY_IMAGE_MUTATION,
        variables: {
          input: {
            file,
          },
        },
      })
      .then(() => {
        setFileList(createFileModel(file.name, "done", file.uid));
        message.success(uppercaseFirstLetter(successMsg));
      })
      .catch(() => {
        message.error(uppercaseFirstLetter(errorMsg));
      });
  };

  const removeFile = () => {
    client
      .mutate({
        mutation: DELETE_DEFAULT_CATEGORY_IMAGE_MUTATION,
        variables: {
          input: {},
        },
      })
      .then(() => {
        message.success(uppercaseFirstLetter(successMsg));
        setFileList([]);
      })
      .catch(() => {
        message.error(uppercaseFirstLetter(errorMsg));
      });
  };

  return (
    <UploadWrapper>
      <StyledTitle level={4}>
        {uppercaseFirstLetter(t("default-image"))}
      </StyledTitle>
      <StyledGrid padding='1rem 0 0 0' width='400px' cols={"auto auto auto"} >
          <Upload
            multiple={false}
            customRequest={uploadFile}
            onRemove={removeFile}
            fileList={fileList}
          >
            <Button>
              <Icon type="upload"/> {uppercaseFirstLetter(t("add-default-image"))}
            </Button>
          </Upload>
          {showBrandSelect && (
            <Select name='brand' onChange={onBrandChange} value={brand}>
              <Select.Option value='V'>Vaillant</Select.Option>
              <Select.Option value='S'>Saunier Duval</Select.Option>
            </Select>
          )}
      <CategoriesTree brand={brand} />
      </StyledGrid>
    </UploadWrapper>
  );
};

const DefaultCategoryImage = withTranslation("categoriesAndSubcategories")(
  DefaultCategory
);

export default DefaultCategoryImage;
