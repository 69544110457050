import {Table, DatePicker} from 'antd';
import React from "react";
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const OfferStatistics = () =>{
    return(
        <>
            <div>
                <p>Wybierz zakres dat aby wygenerować raport ilości ofert</p>
                <RangePicker />
            </div>
            <Table
                columns={[
                    {title:"ID", dataIndex: 'id', key:"id"},
                    {title:"Konsultant", dataIndex: 'consultant', key:"consultant"},
                    {title:"Ilość ofert", dataIndex: 'count_offers', key:"count_offers"},
                ]}
                dataSource={[
                    {id: 1, consultant:"Jan Kowalski", count_offers:10},
                    {id: 1, consultant:"Jan Kowalski", count_offers:10},
                    {id: 1, consultant:"Jan Kowalski", count_offers:10},
                    {id: 1, consultant:"Jan Kowalski", count_offers:10},
                    {id: 1, consultant:"Jan Kowalski", count_offers:10},
                    {id: 1, consultant:"Jan Kowalski", count_offers:10},
                    {id: 1, consultant:"Jan Kowalski", count_offers:10},
                    {id: 1, consultant:"Jan Kowalski", count_offers:10},
                    {id: 1, consultant:"Jan Kowalski", count_offers:10},
                    {id: 1, consultant:"Jan Kowalski", count_offers:10},
                ]}
            />
        </>
    )
}
export default OfferStatistics