import styled from "styled-components";

import { Typography } from "antd";

const { Title } = Typography;

export const UploadWrapper = styled.div`
  margin-bottom: 20px;
  .ant-upload-list-item {
    max-width: 200px;
    margin-top: 10px;
  }
`;

export const StyledTitle = styled(Title)`
  font-size: ${({ theme }) => theme.font.m};
`;
