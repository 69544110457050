import gql from 'graphql-tag';

const GET_PARAMETERS_BY_CATEGORY = gql`
query($id:ID){
  general{
    categories(id: $id){
      edges{
        node{
          parameters{
            edges{
              node{
                id,
                name,
                brand,
                parameterType,
                type,
                choices
                unit
                bulkEditable
              }
            }
          }
        }
      }
    }
  }
}
`

export default GET_PARAMETERS_BY_CATEGORY;