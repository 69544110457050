import styled from "styled-components";

const StyledGrid = styled.div`
  display: grid;
  width: ${({ width }) => width || '100%'};
  grid-template-columns: ${({ cols }) => cols || 'auto auto'};
  grid-gap: ${({ gap }) => gap || '1rem'};
  align-items: center;
  padding: ${({ padding }) => padding || 0};
`;

export default StyledGrid;
