import React, {createContext, useState} from 'react'

export const BulkProductEditorContext = createContext({
    editProducts: [],
    setEditProducts: ()=>null,
    editCategory:null,
    setEditCategory: () =>null,
    categoryParameters: [],
    setCategoryParameters: ()=>null,
    selectedParameters: [],
    setSelectedParameters: ()=>null,
    reloadMainTable: false,
    setReloadMainTable: ()=>null
})

export const BulkProductEditorProvider = ({children}) =>{
    const [editProducts, setEditProducts] = useState([])
    const [editCategory, setEditCategory] = useState()
    const [categoryParameters, setCategoryParameters] = useState([])
    const [selectedParameters, setSelectedParameters] = useState([])
    const [reloadMainTable, setReloadMainTable] = useState(false)
    const value= {
        editProducts,
        setEditProducts,
        editCategory,
        setEditCategory,
        setCategoryParameters,
        categoryParameters,
        selectedParameters,
        setSelectedParameters,
        reloadMainTable,
        setReloadMainTable
    }

    return(
        <BulkProductEditorContext.Provider value={value}>{children}</BulkProductEditorContext.Provider>
    )
}