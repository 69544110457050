import React from "react";
import {StyledTitle} from "../../../components/_styledComponents";
import Layout from "../../Layout/Layout";
import BulkProductsEditor from "../../../components/BulkProductsEditor/BulkProductsEditorMain";
import {BulkProductEditorProvider} from "../../../logic/context/BulkProductEditorContext/BulkProductEditorContext";

import { withTranslation } from "react-i18next";
const BulkProductsEditorView = ({t}) =>{
    return (
        <Layout>
            <StyledTitle>Masowa edycja produktów</StyledTitle>
            <BulkProductEditorProvider>
                <BulkProductsEditor />
            </BulkProductEditorProvider>
        </Layout>
    )
}

export default BulkProductsEditorView;