import gql from 'graphql-tag'

const PARAMETER_PRODUCTS_MUTATION = gql`
mutation($input:ParameterProductsMutationInput!){
  parameterProductsMutation(input: $input){
    edited
  }
}
`

export default PARAMETER_PRODUCTS_MUTATION