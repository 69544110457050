import Layout from "../../structure/Layout/Layout";
import Cookies from "universal-cookie";
import {StyledTitle} from "../_styledComponents";
import {withTranslation} from "react-i18next";
import {Tabs, Table, Button, Input, Tree, Icon,} from 'antd';
import React from "react";
import OfferCreator from "../OfferCreator/OfferCreator";
import OffersList from "../OffersList/OffersList";
import OfferStatistics from "../OfferStatistics/OfferStatistics";
import OfferProductCatalogue from "../OfferProductCatalogue/OfferProductCatalogue";
import {
    ProductSelectionSystemContext,
    ProductSelectionSystemProvider
} from "../../logic/context/ProductSelectionSystemContext/ProductSelectionSystemContext";
const { TabPane } = Tabs;
const { TreeNode } = Tree;

const ProductSelectionSystemMain = ({location, t}) =>{
    return(
        <ProductSelectionSystemProvider>
            <Layout>
                <StyledTitle>
                    {t('productsSelectionSystem')}
                </StyledTitle>
                <Tabs defaultActiveKey="1">
                    <TabPane tab={"Katalog produktów"} key="1">
                        <OfferProductCatalogue />
                    </TabPane>
                    <TabPane tab={"Oferty wzorcowe"} key="2">
                        <OffersList />
                    </TabPane>
                    {/* TODO: IT IS READY VIEW FOR NEXT FUNCTIONS CREATED BY TICKET #3124 */}
                    {/*<TabPane tab={"Statystyki ofert"} key="3">*/}
                    {/*    <OfferStatistics />*/}
                    {/*</TabPane>*/}
                </Tabs>
                <OfferCreator />
            </Layout>
        </ProductSelectionSystemProvider>
            )
}

export default withTranslation('productsSelectionSystem')(ProductSelectionSystemMain)