import Layout from "../../Layout/Layout";
import Cookies from "universal-cookie";
import {StyledTitle} from "../../../components/_styledComponents";
import {withTranslation} from "react-i18next";
import {Tabs, Table, Button, Input, Tree, Icon, DatePicker} from 'antd';
import React from "react";
import ProductSelectionSystemMain from "../../../components/ProductSelectionSystemMain";

const { TabPane } = Tabs;
const { TreeNode } = Tree;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

const ProductSelectionSystemView = ({location, t}) => {
    return(
        <ProductSelectionSystemMain />
    )
}

export default withTranslation('productsSelectionSystem')(ProductSelectionSystemView)