import React, {useContext, useState, useEffect} from "react";
import {Tabs, Table, Button, Input, Tree, Icon, DatePicker, message, InputNumber, Select, Popconfirm} from 'antd';
import DRAFT_OFFER_MUTATION from "../../gql/mutations/DRAFT_OFFER_MUTATION";
import client from "../../config/apollo/client";


import {
    ProductSelectionSystemContext
} from "../../logic/context/ProductSelectionSystemContext/ProductSelectionSystemContext";

const { Option } = Select;

const OfferCreator = () =>{

    const {selectedProducts, setSelectedProducts, setBrand, brand, setOfferName, offerName,editedOfferId, setEditedOfferId, offerStatus, setOfferStatus} = useContext(ProductSelectionSystemContext)

    useEffect(() => {
        if(selectedProducts.length>0 && offerName.length===0){
            setOfferName(selectedProducts[0].name)
            if(brand==null){
                setBrand(selectedProducts[0].brandDisplay)
            }
        }
    }, [selectedProducts]);

    const clearCreator = () =>{
        setBrand(null)
        setSelectedProducts([])
        setOfferName("")
        setEditedOfferId(false)
        setOfferStatus("DRAFT")
    }

    const deleteProduct = (record) =>{
        let tmp_selected_products = selectedProducts.filter((product)=>{
            return product.id!=record.id
        })
        setSelectedProducts(tmp_selected_products)
        if(tmp_selected_products.length===0){
            clearCreator()
        }
    }

    const changeQuantity = (value, record) =>{
        let tmp_selected_products = selectedProducts.filter((product)=>{
            if(product.id===record.id){
                product.quantity = value
            }
            return product
        })
        setSelectedProducts(tmp_selected_products)
    }

    const saveOffer = () =>{
        let parsed_selected_products = []
        selectedProducts.map(
            (product)=>{
                if(!product.unitPrice){
                    product.unitPrice = product.priceValue
                }
                parsed_selected_products.push({
                    productId:product.id,
                    quantity:product.quantity,
                    unitPrice:product.unitPrice,
                    valuePrice:product.unitPrice*product.quantity
                })
            }
        )
        let input = {
            "name": offerName,
            "brand": brand,
            "products": parsed_selected_products,
            status:offerStatus
        }
        if(editedOfferId){
            input.editedOfferId = editedOfferId
        }
        client.mutate(
            {
                mutation: DRAFT_OFFER_MUTATION,
                variables:{
                    input: input
                }
            }
        ).then((res)=>{
            if(res.data.draftOfferMutation.created){
                if(editedOfferId){
                    message.success("Oferta edytowana")
                }else{
                    message.success("Oferta utworzona")
                }
                clearCreator()

            }
        })
    }

    if(!brand)
        return null

    return(
        <>
            <hr/>
            {editedOfferId? <h2>Edycja oferty {brand&&<>dla marki {brand}</>}</h2> : <h2>Nowa oferta wzorcowa {brand&&<>dla marki {brand}</>}</h2>}
            <p style={{fontSize: "0.8em"}}>Aby wyszukać i dodać nowe produkty przejdź do zakładki "Katalog Produktów"</p>
            <Table pagination={false}
                   columns={[
                       {title:"Nr katalogowy", dataIndex:'catalogueNumber', key: "catalogueNumber"},
                       {title:"Opis produktu", dataIndex:'name', key: "name"},
                       {
                           title:"Ilość",
                           dataIndex:'quantity',
                           key: "quantity",
                           render: (_, record)=><><InputNumber min={1} onChange={(value)=>changeQuantity(value,record)} value={record.quantity} /></>
                       },
                       {
                           title: "Cena jedn,",
                           dataIndex: 'priceValue',
                           key: "priceValue",
                           render: (_, record)=>{
                               return record.unitPrice?<>{record.unitPrice}</>:<>{record.priceValue}</>
                           }

                       },
                       {
                           title: "Wartość",
                           dataIndex: "value",
                           key: "value",
                           render: (_, record)=>{
                               return record.unitPrice?<>{record.quantity*record.unitPrice}</>:<>{record.quantity*record.priceValue}</>
                           }
                       },
                       {
                           title: "Actions",
                           dataIndex: "actions",
                           key: "actions",
                           render: (_,record)=><Button onClick={()=>deleteProduct(record)}><Icon type="delete" /></Button>
                       }
                   ]}

                   dataSource={selectedProducts}
                   footer={()=>
                       <div>
                           <div style={{display: "flex"}}>
                               <Input
                                   placeholder="Nazwa oferty wzorcowej"
                                   value={offerName}
                                   onChange={(e)=>setOfferName(e.target.value)}
                                   allowClear={true}
                               />
                               <Select onChange={(value)=>setOfferStatus(value)} value={offerStatus} style={{width: "15%"}}>
                                   <Option value="DRAFT">DRAFT</Option>
                                   <Option value="ACTIVE">AKTYWNY</Option>
                                   <Option value="ARCHIVE">ARCHIWALNY</Option>
                               </Select>
                           </div>
                           <Button onClick={saveOffer}>{editedOfferId? <>Zapisz zmiany</>:<>Utwórz nową ofertę wzorcową</>}</Button>
                           <Popconfirm title={"Czy na pewno ?"} onConfirm={clearCreator} okText={"Tak"} cancelText={"Nie"}>
                            <Button type={"danger"} ghost={true}>Anuluj</Button>
                           </Popconfirm>
                       </div>}
            />
        </>
    )
}

export default OfferCreator