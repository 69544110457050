import {Tabs, Table, Button, Input, Tree, Icon, DatePicker} from 'antd';
import React, {useContext, useState, useEffect} from "react";
import client from "../../config/apollo/client";
import GET_DRAFT_OFFER_LIST from "../../gql/queries/GET_DRAFT_OFFER_LIST";
import GET_PRODUCT_DRAFT_OFFER_BY_OFFER_ID from "../../gql/queries/GET_PRODUCT_DRAFT_OFFER_BY_OFFER_ID";
import OfferDetailsProductsList from "../OfferDetailProductsList/OfferDetailsProductsList";
import {ProductSelectionSystemContext} from "../../logic/context/ProductSelectionSystemContext/ProductSelectionSystemContext";



const OffersList = () =>{

    const [draftOffers, setDraftOffers] = useState([])
    const [filterOffers, setFilterOffers] = useState("")
    const [allDraftOffers, setAllDraftOffers] = useState([])
    const {selectedProducts, setSelectedProducts, brand, setBrand, offerName, setOfferName,editedOfferId, setEditedOfferId, offerStatus, setOfferStatus} = useContext(ProductSelectionSystemContext)

    const getProducts = (offer_id) =>{
    }

    const addProducts = (offer) =>{
        client.query({
            query: GET_PRODUCT_DRAFT_OFFER_BY_OFFER_ID,
            variables:{
                'draftOfferId': offer.id
            }
        }).then(
            (res)=>{
                let tmp_selected_products = []
                res.data.general.productDraftOffer.edges.map(
                    (node)=>{
                        let product = node.node.product
                        product.quantity = node.node.quantity
                        tmp_selected_products.push(product)
                    })
                setSelectedProducts(tmp_selected_products)
                setOfferName(offer.name)
                console.log(offer)
                setEditedOfferId(false)
                setOfferStatus(offer.status)
            }
        )
    }
    const editProducts = (offer) =>{
        client.query({
            query: GET_PRODUCT_DRAFT_OFFER_BY_OFFER_ID,
            variables:{
                'draftOfferId': offer.id
            }
        }).then(
            (res)=>{
                let tmp_selected_products = []
                res.data.general.productDraftOffer.edges.map(
                    (node)=>{
                        let product = node.node.product
                        product.quantity = node.node.quantity
                        tmp_selected_products.push(product)
                    })
                setSelectedProducts(tmp_selected_products)
                setOfferName(offer.name)
                setEditedOfferId(offer.id)
                setOfferStatus(offer.status)
            }
        )
    }

    const getDraftOfferList = () =>{
        client.query({
            query:GET_DRAFT_OFFER_LIST,
            fetchPolicy: 'network-only'
        }).then(
            (res)=>{
                let tmp_draft_offer = []
                res.data.general.draftOffer.edges.map(
                    (node)=>tmp_draft_offer.push(node.node)
                )
                setDraftOffers(tmp_draft_offer)
                setAllDraftOffers(tmp_draft_offer)
            }
        )
    }

    useEffect(()=>{
        getDraftOfferList()
    },[])

    useEffect(() => {
        getDraftOfferList()
    }, [selectedProducts]);

    useEffect(() => {
        if(filterOffers){
            let tmp_filtered_offers = []
            tmp_filtered_offers = allDraftOffers.filter(
                (offer)=>{
                    return offer.name.toUpperCase().includes(filterOffers.toUpperCase())
                }
            )
            setDraftOffers(tmp_filtered_offers)
        }else{
            setDraftOffers(allDraftOffers)
        }
    }, [filterOffers,allDraftOffers]);

    return(
        <>
        <Input placeholder="Wyszukaj po nazwie" onChange={(event)=>setFilterOffers(event.target.value)} />
        <Table columns={[
            {
                title:"Opis oferty",
                dataIndex: 'name',
                key:"name",
            },
            {
                title:"Status",
                dataIndex: 'status',
                key:"status",
                filters: [
                    {
                        text: "DRAFT",
                        value: "DRAFT"
                    },
                    {
                        text: "AKTYWNY",
                        value: "ACTIVE"
                    },
                    {
                        text: "ARCHIWALNY",
                        value: "ARCHIVE"
                    },
                ],
                onFilter: (value, record) => record.status.includes(value)
            },
            {
                title: "Brand",
                dataIndex: "brand",
                key:"brand",
                filters:[
                  {
                      text: "Vaillant",
                      value:"V",
                  },
                  {
                      text: "Saunier Duval",
                      value: "S"
                  }
                ],
                onFilter: (value, record) =>record.brand.includes(value),
                render: (_, record) => record.brand=="V"? <>Vaillant</>:<>Saunier Duval</>
            },
            {title:"Akcje", dataIndex: 'actions', key:"actions", render: (_, record)=><><Button onClick={()=>editProducts(record)}><Icon type="edit" /></Button><Button onClick={()=>addProducts(record)}><Icon type="plus-square" /></Button></>},
        ]}
               expandedRowRender = {record=><OfferDetailsProductsList offer_id={record.id} />}
               dataSource={draftOffers}
        />
        </>
    )
}

export default OffersList