import React, {useState, useEffect, useContext} from "react";
import {Table, Button, Input, Tree, Icon, Select, Drawer, message} from 'antd';
import client from "../../config/apollo/client";
import PRODUCTS_TABLE_QUERY from "../../gql/queries/PRODUCTS_TABLE_QUERY";
import BRAND_QUERY from "../../gql/queries/BRAND_QUERY";
import MAIN_CATEGORY_QUERY from "../../gql/queries/MAIN_CATEGORY_QUERY";
import TREE_CATEGORY_QUERY from "../../gql/queries/TREE_CATEGORY_QUERY";
import {
    ProductSelectionSystemContext
} from "../../logic/context/ProductSelectionSystemContext/ProductSelectionSystemContext";

const { TreeNode } = Tree;
const OfferProductCatalogue = () =>{

    const pageSize = 9;
    const [allProductsData, setAllProductsData] = useState([])

    const [productsData, setProductsData] = useState([])

    const [tableLoading, setTableLoading] = useState(false)

    const [filterProducts, setFilterProducts] = useState("")
    const [brands, setBrands] = useState([]);
    const [brandFilter, setBrandFilter] = useState("")
    const [categoryFilter, setCategoryFilter] = useState("")
    const [categories, setCategories ] = useState([])
    const [showCategories, setShowCategories] = useState(false)
    const [currentPage, updateCurrentPage] = useState(1)
    const [totalRecords, updateTotalRecords] = useState(0);

    const {selectedProducts, setSelectedProducts, brand} = useContext(ProductSelectionSystemContext)

    const products_columns = [
        {
            title:"Nr katalogowy",
            dataIndex:'catalogueNumber',
            key: "catalogue_no",
        },
        {
            title:"Opis produktu",
            dataIndex:'name',
            key: "name",
        },
        {title: "Cena jedn,", dataIndex: 'priceValue', key: "priceValue"},
        {
            title: "Akcje",
            dataIndex: "actions",
            key: "actions",
            render: (_, record)=><Button onClick={()=>addProductToOffer(record)}><Icon type="plus-square" /></Button>
        }
    ]

    const getTableData = () => {
        setTableLoading(true)
        let offset = (currentPage - 1) * pageSize;
        client.query({
            query: PRODUCTS_TABLE_QUERY,
            fetchPolicy: "network-only",
            variables: {
                brand: brandFilter,
                categoryId: categoryFilter,
                search: filterProducts,
                pageSize: pageSize,
                offset: offset
            },
        }).then(
            (res)=>{
                let tmp_products = []
                res.data.general.products.edges.map(
                    (record)=>{
                        tmp_products.push(
                            record.node
                        )
                    }
                )
                setAllProductsData(tmp_products)
                setProductsData(tmp_products)
                setTableLoading(false)
                updateTotalRecords(res.data.general.products.totalCount);
            }
        )
    }

    const getBrands = () => {
        client
            .query({
                query: BRAND_QUERY,
            })
            .then((res) => {
                setBrands(res.data.general.brands);
            });
    }

    const getCategories = () => {
        if(brandFilter){
            client.query({
                query: TREE_CATEGORY_QUERY,
                fetchPolicy: "network-only",
                variables: {
                    brand: brandFilter,
                },
            }).then((res)=> {
                setCategories(res.data.general.categories.edges)
            })
            setCategoryFilter("")
        }
    }

    const addProductToOffer = (product) =>{
        if(brand!==null && brand!==product.brandDisplay){
            message.error("Zabronione jest mieszanie produktów od różnych marek")
            return null
        }
        let tmp_selected_products = [...selectedProducts]
        let quantity_products = 1
        const res = tmp_selected_products.filter((record)=>{
            if (record.id === product.id){
                quantity_products = record.quantity+=1
            }
        })
        if(quantity_products<2){
            product.quantity = quantity_products
            tmp_selected_products.push(product)
        }
        setSelectedProducts(tmp_selected_products)

    }

    const onTableChange = (pagination, filters, sorter) => {
        if (pagination.current !== currentPage) {
            updateCurrentPage(pagination.current);
        }
    }

    useEffect(() => {
        getTableData()
        getBrands()
    }, []);

    useEffect(() => {
        getCategories()
        getTableData()
    }, [brandFilter]);

    useEffect(() => {
        getTableData()
    }, [categoryFilter, filterProducts, currentPage]);

    useEffect(() => {
        updateCurrentPage(1);
    }, [categoryFilter, filterProducts]);

    return(
        <>
            <div  style={{display: "flex", justifyContent:"center", flexDirection:"row"}}>
                <Select style={{width: "200px"}} value={brandFilter}  onChange={(e)=> {setBrandFilter(e)}} placeholder="Wybierz markę">
                    {brands.map((obj, idx) => {
                        return (
                            <Select.Option value={obj[0]} key={idx}>
                                {obj[1]}
                            </Select.Option>
                        );
                    })}
                </Select>
                <Button disabled={!categories.length} onClick={()=>{setShowCategories(!showCategories)}}>
                    Kategorie
                </Button>
                <Input placeholder="Wyszukaj po nazwie lub numerze katalogowym" onChange={(event)=>setFilterProducts(event.target.value)} />
            </div>
                <div style={{display: "flex", justifyContent:"center", flexDirection:"row"}}>
                    <Drawer
                        visible={showCategories}
                        onClose={()=>{setShowCategories(!showCategories)}}
                        width={"50%"}
                        title={"Kategorie"}
                    >
                    <div>
                        <Tree onSelect={(selectedKeys)=>{
                                if(selectedKeys[0]==="all"){
                                    setCategoryFilter("")
                                }else{
                                    setCategoryFilter(selectedKeys[0])
                                }
                            }
                        } showLine={true} allowClear={true}>
                            <TreeNode title={"Wszystkie produkty"} key={"all"}>
                            </TreeNode>
                            {categories.map((c)=>
                                <TreeNode title={c.node.name} key={c.node.id}>
                                    {c.node.children.edges.map((c2)=>
                                        <TreeNode title={c2.node.name}  key={c2.node.id}>
                                            {c2.node.children.edges.map((c3)=>
                                                <TreeNode title={c3.node.name}  key={c3.node.id}>
                                                    {c3.node.children.edges.map((c4)=>
                                                        <TreeNode title={c4.node.name}  key={c4.node.id}>
                                                            {c4.node.children.edges.map((c5)=>
                                                                <TreeNode title={c5.node.name}  key={c5.node.id}>
                                                                    {c5.node.children.edges.map((c6)=>
                                                                        <TreeNode title={c6.node.name}  key={c6.node.id}>
                                                                            {c6.node.children.edges.map((c7)=>
                                                                                <TreeNode title={c7.node.name}  key={c7.node.id}>
                                                                                    {c7.node.children.edges.map((c8)=>
                                                                                        <TreeNode title={c8.node.name}  key={c8.node.id}>
                                                                                            {c8.node.children.edges.map((c9)=>
                                                                                                <TreeNode title={c9.node.name}  key={c9.node.id}>
                                                                                                </TreeNode>
                                                                                            )}
                                                                                        </TreeNode>
                                                                                    )}
                                                                                </TreeNode>
                                                                            )}
                                                                        </TreeNode>
                                                                    )}
                                                                </TreeNode>
                                                            )}
                                                        </TreeNode>
                                                    )}
                                                </TreeNode>
                                            )}
                                        </TreeNode>
                                    )}
                                </TreeNode>
                            )}
                        </Tree>
                    </div>
                </Drawer>
                <div style={{width:"100%"}}>
                    <Table
                        columns={products_columns}
                        dataSource={productsData}
                        loading={tableLoading}
                        onChange={onTableChange}
                        pagination={{
                            pageSize,
                            total: totalRecords,
                            currentPage: currentPage
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default OfferProductCatalogue