import auth from './auth';
import products from './products';
import employees from './employees';
import dataExport from './dataExport';
import priceImport from './priceImport';
import translationButton from './translationButton'
import addProduct from './addProduct';
import steps from './steps';
import documents from './documents';
import prices from './prices';
import passwordReset from "./passwordReset";
import promotions from './promotions';
import categoriesAndSubcategories from './categoriesAndSubcategories';
import productsSelectionSystem from "./productsSelectionSystem";

export default {
  auth,
  products,
  translationButton,
  addProduct,
  steps,
  documents,
  prices,
  employees,
  dataExport,
  priceImport,
  passwordReset,
  promotions,
  categoriesAndSubcategories,
  productsSelectionSystem
};

