import React, {useContext, useEffect, useState} from "react";
import {BulkProductEditorContext} from "../../logic/context/BulkProductEditorContext/BulkProductEditorContext";
import GET_PARAMETERS_BY_CATEGORY from "../../gql/queries/GET_PARAMETERS_BY_CATEGORY";
import client from "../../config/apollo/client";
import "./BulkParametersProductsEditorTable.styles.css"
import {Button, Input, Table,  Icon} from 'antd'
import BulkParametersEditorForm from "../BulkParametersEditorForm/BulkParametersEditorForm";

const BulkParametersProductsEditorTable = () =>{
    const {editProducts, editCategory, selectedParameters} = useContext(BulkProductEditorContext)
    const [parametersChange, setParametersChange] = useState([])
    if(editProducts.length==0|| !editCategory){
        return null
    }
    const handleChangeParameter = (id, value) =>{
        const parameters = [...parametersChange]
        const existingParameter = parameters.find((parameter)=>{
            return parameter.id==id
        })
        if(existingParameter){
            parameters.map((parameter)=>{
                if(parameter.id===id){
                    parameter.value=value
                }
            })
        }else{
            parameters.push(
                {
                    id: id,
                    value:value
                }
            )
        }
        setParametersChange(parameters)
    }

    const columns = [
        {
            title: "Nazwa parametru",
            render: (record)=>record.node.name
        },
        {
            title: "Nowa wartość",
            render: (record)=><BulkParametersEditorForm key={record.node.id} parameter={record} />
        }
    ]
    if(!editProducts)
        return null
    return(
        <div>
            <Table columns={columns} dataSource={selectedParameters} />
        </div>
    )
}
export default BulkParametersProductsEditorTable