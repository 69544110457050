import gql from 'graphql-tag'

const GET_PRODUCT_DRAFT_OFFER_BY_OFFER_ID = gql`
query getProductDraftOfferByOfferId($draftOfferId:ID){
  general{
    productDraftOffer(draftOffer_Id:$draftOfferId){
      edges{
        node{
          id,
          quantity,
          unitPrice,
          valuePrice,
          product{
            id,
            name,
            catalogueNumber,
            priceValue,
            brandDisplay
          },
          draftOffer{
            id,
          }
        }
      }
    }
  }
}
`
export default GET_PRODUCT_DRAFT_OFFER_BY_OFFER_ID