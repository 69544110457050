import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { routes } from "../../../../config/routes";

import uppercaseFirstLetter from "../../../../utils/uppercaseFirstLetter";

import {
  StyledBreadcrumbWrapper,
  StyledBreadcrumb,
  StyledPrimaryLink,
  StyledSecondaryLink,
} from "../../../_styledComponents/CategoriesTable/Breadcrumb";

const BreadcrumbComponent = ({ categories, brand, t }) => {
  const [isMainCategory, setIsMainCategory] = useState(false);
  const location = useLocation();

  const createBreadcrumb = () => {
    if (isMainCategory) {
      return null;
    }
    if (categories.length > 0) {
      const paths = categories[0].node.pathToRoot.slice(0, -1);

      const resultToRender = paths.map((singlePathData) => (
        <div key={singlePathData.id}>
          <StyledSecondaryLink to={singlePathData.id}>
            {singlePathData.displayName || singlePathData.name} /
          </StyledSecondaryLink>
        </div>
      ));

      return resultToRender;
    }
    return null;
  };
  useEffect(() => {
    setIsMainCategory(routes.CATEGORIES === location.pathname);
    createBreadcrumb();
  }, [categories]);

  return (
    <StyledBreadcrumbWrapper>
      {categories && !isMainCategory && (
        <StyledBreadcrumb>
          <StyledPrimaryLink to={`${routes.CATEGORIES}/${brand}`}>
            {uppercaseFirstLetter(t("categories"))}
          </StyledPrimaryLink>
          /{createBreadcrumb()}
        </StyledBreadcrumb>
      )}
    </StyledBreadcrumbWrapper>
  );
};

BreadcrumbComponent.propTypes = {
  categories: PropTypes.array.isRequired,
};

const TranslatedBreadcrumb = withTranslation("categoriesAndSubcategories")(
  BreadcrumbComponent
);

export default TranslatedBreadcrumb;
