import React, {useContext, useState, useEffect} from "react";
import client from "../../config/apollo/client";
import GET_PRODUCT_DRAFT_OFFER_BY_OFFER_ID from "../../gql/queries/GET_PRODUCT_DRAFT_OFFER_BY_OFFER_ID";
import {Tabs, Table, Button, Input, Tree, Icon, DatePicker, message} from 'antd';
import {ProductSelectionSystemContext} from "../../logic/context/ProductSelectionSystemContext/ProductSelectionSystemContext";

const OfferDetailsProductsList = ({offer_id}) =>{

    const [offerDetails, setOfferDetails] = useState()
    const {selectedProducts, setSelectedProducts, brand, setBrand} = useContext(ProductSelectionSystemContext)


    const getOfferDetails = () =>{
        client.query({
            query: GET_PRODUCT_DRAFT_OFFER_BY_OFFER_ID,
            fetchPolicy: 'network-only',
            variables:{
                'draftOfferId': offer_id
            }
        }).then(
            (res)=>{
                setOfferDetails(res.data.general.productDraftOffer.edges)
            }
        )
    }

    const addProductToOffer = (record) =>{
        const product = record.node.product
        if(brand!==null && brand!==product.brandDisplay){
            message.error("Zabronione jest mieszanie produktów od różnych marek")
            return null
        }
        let tmp_selected_products = [...selectedProducts]
        let quantity_products = 1
        const res = tmp_selected_products.filter((record)=>{
            if (record.id === product.id){
                quantity_products = record.quantity+=1
            }
        })
        if(quantity_products<2){
            product.quantity = quantity_products
            tmp_selected_products.push(product)
        }
        console.log(tmp_selected_products)
        setSelectedProducts(tmp_selected_products)

    }

    useEffect(() => {
        getOfferDetails()
    }, []);

    useEffect(() => {
        getOfferDetails()
    }, [selectedProducts]);

    return(
        <Table pagination={false}
               columns={[
                   {title:"Nr katalogowy", dataIndex:'catalogue_no', key: "catalogue_no", render: (_,record)=>record.node.product.catalogueNumber},
                   {title:"Opis produktu", dataIndex:'product_description', key: "product_description", render: (_,record)=>record.node.product.name},
                   {title:"Ilość", dataIndex:'quantity', key: "quantity", render: (_,record)=>record.node.quantity},
                   {title: "Cena jedn,", dataIndex: 'price', key: "price", render: (_,record)=>record.node.unitPrice},
                   {title: "Wartość", dataIndex: "value", key: "value", render: (_,record)=>record.node.quantity*record.node.unitPrice},
                   {
                       title: "Akcje",
                       dataIndex: "actions",
                       key: "actions",
                       render: (_, record)=><Button onClick={()=>addProductToOffer(record)}><Icon type="plus-square" /></Button>
                   }
               ]}
               dataSource={offerDetails}
        />
    )
}

export default OfferDetailsProductsList