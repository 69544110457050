import React, { useState, useEffect } from 'react';
import {Button, message, Modal, Tree, Spin} from "antd";
import {withTranslation} from "react-i18next";
import client from "../../config/apollo/client";
import TREE_CATEGORY_QUERY from "../../gql/queries/TREE_CATEGORY_QUERY";
import uppercaseFirstLetter from "../../utils/uppercaseFirstLetter";
import {get} from "lodash";

const {TreeNode, DirectoryTree} = Tree;

const CategoriesTree = ({t, brand}) =>{
    const [open, setOpen] = useState(false);
    const [ all_categories, setAllCategories ] = useState([]);

    const setErrorMsg = (errorType) =>
        uppercaseFirstLetter(
            t(`something-went-wrong-while-${errorType}-categories`)
        );
    const fetchAllCategories = () => {
        client
            .query({
                query: TREE_CATEGORY_QUERY,
                fetchPolicy: "network-only",
                variables: {
                    brand,
                },
            })
            .then(({data}) => {
                const categories = get(data, "general.categories.edges");
                setAllCategories(categories);
            })
            .catch(() => message.error(setErrorMsg("fetching")));
    };
    useEffect(()=>{
        fetchAllCategories()
    }, brand)
    // TODO: Construction below is to refactor
    return(
        <>
            <Button onClick={() => setOpen(true)}>Wyświetl strukturę</Button>
            <Modal
                title="Struktura kategorii"
                centered
                visible={open}
                onCancel={()=>setOpen(false)}
                footer={[
                    <Button onClick={() => setOpen(false)}>Zamknij</Button>,
                ]}
                width="80%">
                {all_categories.length>0 ?<DirectoryTree showLine>
                    {all_categories.map((c)=>
                        <TreeNode title={c.node.name}>
                            {c.node.children.edges.map((c2)=>
                                <TreeNode title={c2.node.name}>
                                    {c2.node.children.edges.map((c3)=>
                                        <TreeNode title={c3.node.name}>
                                            {c3.node.children.edges.map((c4)=>
                                                <TreeNode title={c4.node.name}>
                                                    {c4.node.children.edges.map((c5)=>
                                                        <TreeNode title={c5.node.name}>
                                                            {c5.node.children.edges.map((c6)=>
                                                                <TreeNode title={c6.node.name}>
                                                                    {c6.node.children.edges.map((c7)=>
                                                                        <TreeNode title={c7.node.name}>
                                                                            {c7.node.children.edges.map((c8)=>
                                                                                <TreeNode title={c8.node.name}>
                                                                                    {c8.node.children.edges.map((c9)=>
                                                                                        <TreeNode title={c9.node.name}>

                                                                                        </TreeNode>
                                                                                    )}
                                                                                </TreeNode>
                                                                            )}
                                                                        </TreeNode>
                                                                    )}
                                                                </TreeNode>
                                                            )}
                                                        </TreeNode>
                                                    )}
                                                </TreeNode>
                                            )}
                                        </TreeNode>
                                    )}
                                </TreeNode>
                            )}
                        </TreeNode>
                    )}
                </DirectoryTree>:
                    <div style={{display: "flex"}}><h1>Ładuje dane... </h1><Spin size="large" /></div>
                }
            </Modal>
        </>
    )
}

const TranslatedCategoriesTree = withTranslation("categoriesAndSubcategories")(
    CategoriesTree
);

export default TranslatedCategoriesTree;