import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Input,
  Button,
  Dropdown,
  Menu,
  Table,
  Tooltip,
  Popconfirm,
  Row,
  Icon,
  Select,
  Slider,
  Collapse,
  Divider,
  InputNumber,
  Avatar,
} from "antd";
import _, { debounce } from "lodash";
import styled from "styled-components";

import trimString from "../../utils/trimString";
import client from "../../config/apollo/client";
import PRODUCTS_TABLE_QUERY from "../../gql/queries/PRODUCTS_TABLE_QUERY";
import DuplicateProductModal from "../ProductModal";
import UPDATE_PRODUCTS_STATUSES from "../../gql/mutations/UPDATE_PRODUCTS_STATUSES";
import updateProductList from "../../gql/updaters/updateProductList";
import { Query } from "react-apollo";
import REMOVE_PRODUCTS from "../../gql/mutations/REMOVE_PRODUCTS";
import removeProductList from "../../gql/updaters/removeProductList";
import MAIN_CATEGORY_QUERY from "../../gql/queries/MAIN_CATEGORY_QUERY";
import EditProductLink from "./EditProductLink";
import MAX_PRICE_QUERY from "../../gql/queries/MAX_PRICE_QUERY";
import CATEGORY_MAIN_PARAMETERS_QUERY from "../../gql/queries/CATEGORY_MAIN_PARAMETERS_QUERY";
import PARAMETER_CHOICES_QUERY from "../../gql/queries/PARAMETER_CHOICES_QUERY";
import { withTranslation } from "react-i18next";
import { StyledFilterDiv } from "../_styledComponents";
import BRAND_QUERY from "../../gql/queries/BRAND_QUERY";
import ProductFilterContext from "../../logic/context/productFilter/ProductFilterContext";

const Panel = Collapse.Panel;
const InputGroup = Input.Group;

const ImageFitWrapper = styled.div`
  img {
    object-fit: cover;
  }
`;

const ProductsTableComponent = ({ status, search, t, activeKey, tabKey }) => {
  const { filtersContext, setFiltersContext } = useContext(
    ProductFilterContext
  );

  const pageSize = 9;
  const [currentPage, updateCurrentPage] = useState(filtersContext.currentPage);
  const [showDuplicateModal, updateShowDuplicateModal] = useState(false);
  const [totalRecords, updateTotalRecords] = useState(0);
  const [duplicateModalText, updateDuplicateModalText] = useState("");
  const [
    duplicateModalCatalogueNumber,
    updateDuplicateModalCatalogueNumber,
  ] = useState("");
  const [duplicateModalProductId, updateDuplicateModalProductId] = useState(
    null
  );
  const [sortColumn, updateSortColumn] = useState(filtersContext.sortColumn);
  const [minPriceFilter, setMinPriceFilter] = useState(
    filtersContext.minPriceFilter
  );
  const [maxPriceFilter, setMaxPriceFilter] = useState(
    filtersContext.maxPriceFilter
  );
  const [displayMinPrice, setDisplayMinPrice] = useState(
    filtersContext.minPriceFilter
  );
  const [displayMaxPrice, setDisplayMaxPrice] = useState(
    filtersContext.maxPriceFilter
  );
  const [brands, setBrands] = useState([]);
  const [brandFilter, setBrandFilter] = useState(filtersContext.brandFilter);
  const [mainCategories, setMainCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState(
    filtersContext.categoryFilter
  );
  const [paramFilters, setParamFilters] = useState(filtersContext.paramFilters);
  const [maxPrice, setMaxPrice] = useState(0);
  const [filtersNumber, setFiltersNumber] = useState(5);
  let offset = (currentPage - 1) * pageSize;

  const [searchState, setSearchState] = useState(search);

  const [selectedRows, setSelectedRows] = useState([]);
  const [activeDropdown, updateActiveDropdown] = useState(null);

  async function getData() {
    await client
      .query({
        query: MAX_PRICE_QUERY,
      })
      .then((res) => {
        let maxPriceResult = res.data.general.maxPrice;
        if (!maxPriceFilter) {
          setMaxPriceFilter(maxPriceResult);
          setDisplayMaxPrice(maxPriceResult);
        }
        setMaxPrice(maxPriceResult);
      });

    await client
      .query({
        query: PRODUCTS_TABLE_QUERY,
        fetchPolicy: "network-only",
        variables: {
          status,
          pageSize,
          offset,
          sortColumn: sortColumn ? sortColumn : null,
          search,
          categoryId: categoryFilter,
          brand: brandFilter,
          minPrice: minPriceFilter,
          maxPrice: maxPriceFilter,
          filters: paramFilters.filter((filter) => filter),
        },
      })
      .then((res) => {
        updateTotalRecords(res.data.general.products.totalCount);
      });

    await client
      .query({
        query: BRAND_QUERY,
      })
      .then((res) => {
        setBrands(res.data.general.brands);
      });
    if (brandFilter) {
      await client
        .query({
          query: MAIN_CATEGORY_QUERY,
          variables: {
            brand: brandFilter,
          },
        })
        .then((res) => {
          setMainCategories(
            res.data.general.categories.edges.map((obj) => obj.node)
          );
        });
    }
  }

  if (searchState !== search) {
    updateCurrentPage(1);
    setFiltersContext({
      changes: [{ field: "currentPage", value: 1 }],
    });
    setSearchState(search);
  }

  useEffect(() => {
    if (activeKey === tabKey) {
      getData();
    }
  }, [activeKey]);

  useEffect(() => {
    getData();
  }, [paramFilters]);

  const getDefaultSortOrder = (sortName) => {
    if (_.isEqual(sortName, sortColumn)) {
      return "ascend";
    } else if (
      _.isEqual(
        sortName.map((element) => `-${element}`),
        sortColumn
      )
    ) {
      return "descend";
    }
  };

  const getColumns = () => {
    const columns = [
      {
        title: "",
        key: "image",
        dataIndex: "image",
        render: (text, record) =>
          text ? (
            <Tooltip
              overlayStyle={{ maxWidth: "100%" }}
              placement={"bottom"}
              title={
                <img
                  src={process.env.REACT_APP_URL + text}
                  alt={""}
                  style={{ maxHeight: "300px", maxWidth: "300px" }}
                />
              }
            >
              <ImageFitWrapper>
                <Avatar
                  size="large"
                  shape="square"
                  icon="gold"
                  style={{ objectFit: "cover" }}
                  src={record.image && process.env.REACT_APP_URL + record.image}
                />
              </ImageFitWrapper>
            </Tooltip>
          ) : (
            <Avatar size="large" shape="square" icon="gold" />
          ),
      },
      {
        title: t("name"),
        dataIndex: "name",
        sortName: ["name"],
        defaultSortOrder: getDefaultSortOrder(["name"]),
        key: "name",
        sorter: true,
      },
      {
        title: t("category"),
        dataIndex: "category",
        sortName: ["mainCategory__name"],
        defaultSortOrder: getDefaultSortOrder(["mainCategory__name"]),
        key: "category",
        width: "13%",
        sorter: true,
      },
      {
        title: t("catalougeNumber"),
        dataIndex: "catalogueNumber",
        sortName: ["catalogue_number"],
        defaultSortOrder: getDefaultSortOrder(["catalogue_number"]),
        width: "200px",
        key: "catalogueNumber",
        sorter: true,
      },
      {
        title: t("price"),
        dataIndex: "priceValue",
        key: "priceValue",
        sortName: ["price_value"],
        defaultSortOrder: getDefaultSortOrder(["price_value"]),
        width: "90px",
        sorter: true,
      },
      {
        title: t("brand"),
        dataIndex: "brandDisplay",
        key: "brandDisplay",
        sortName: ["brand"],
        defaultSortOrder: getDefaultSortOrder(["brand"]),
        width: "120px",
        sorter: true,
      },
      {
        title: t("updatedAt"),
        dataIndex: "updatedAt",
        key: "updatedAt",
        sortName: ["updated_at"],
        defaultSortOrder: getDefaultSortOrder(["updated_at"]),
        width: "180px",
        sorter: true,
      },
      {
        title: t("modyfiedBy"),
        dataIndex: "updatedBy",
        sortName: ["updated_by__first_name", "updated_by__last_name"],
        defaultSortOrder: getDefaultSortOrder([
          "updated_by__first_name",
          "updated_by__last_name",
        ]),
        key: "updatedBy",
        width: "15%",
        sorter: true,
      },
    ];

    if (!status) {
      columns.push({
        title: t("status"),
        dataIndex: "statusName",
        sortName: ["status"],
        key: "status",
        sorter: true,
      });
    }
    columns.push({
      dataIndex: "more",
      key: "more",
      render: (text, record, index) => {
        return (
          <Dropdown
            onClick={() => {
              if (record.key === activeDropdown) updateActiveDropdown(null);
              else updateActiveDropdown(record.key);
            }}
            trigger={["click"]}
            onVisibleChange={(visible) =>
              !visible &&
              record.key === activeDropdown &&
              updateActiveDropdown(null)
            }
            visible={activeDropdown === record.key}
            overlay={<Menu>{getOptions(record)}</Menu>}
          >
            <Button>{t("more")}</Button>
          </Dropdown>
        );
      },
    });
    return columns;
  };

  const renderConfirmableButton = (text, confirmText, action, key) => {
    return (
      <Menu.Item key={text}>
        <Popconfirm
          style={{ width: "100%", height: "100%" }}
          placement="left"
          title={confirmText}
          okText={t("yes")}
          cancelText={t("no")}
          onConfirm={action}
          onCancel={() => {
            updateActiveDropdown(null);
          }}
        >
          <div style={{ width: "100%", height: "100%" }}>{text}</div>
        </Popconfirm>
      </Menu.Item>
    );
  };

  const updateProductStatus = (id, newStatus, clearSelect = false) => {
    client
      .mutate({
        mutation: UPDATE_PRODUCTS_STATUSES,
        variables: {
          input: {
            productIds: id,
            status: newStatus,
          },
        },
      })
      .then(({ data }) => {
        updateProductList(
          client,
          id,
          {
            status,
            pageSize,
            offset,
            sortColumn: sortColumn ? sortColumn : null,
            search,
            categoryId: categoryFilter,
            minPrice: minPriceFilter,
            maxPrice: maxPriceFilter,
            filters: paramFilters.filter((filter) => filter),
            brand: brandFilter,
          },
          newStatus
        ).then(() => {
          updateActiveDropdown(null);
          if (clearSelect) {
            setSelectedRows([]);
          }
        });
      });
  };

  const removeProduct = (id) => {
    client
      .mutate({
        mutation: REMOVE_PRODUCTS,
        variables: {
          input: {
            productIds: id,
          },
        },
      })
      .then(({ data }) => {
        removeProductList(client, id, {
          status,
          pageSize,
          offset,
          sortColumn: sortColumn ? sortColumn : null,
          search,
          categoryId: categoryFilter,
          minPrice: minPriceFilter,
          maxPrice: maxPriceFilter,
          filters: paramFilters.filter((filter) => filter),
          brand: brandFilter,
        }).then(() => updateActiveDropdown(null));
      });
  };

  const getOptions = (record) => {
    const constantOptions = [
      <Menu.Item
        key={1}
        onClick={() => {
          updateShowDuplicateModal(true);
          updateActiveDropdown(null);
          updateDuplicateModalText(`${record.name.props.title}` + t("-copy"));
          updateDuplicateModalCatalogueNumber(record.catalogueNumber);
          updateDuplicateModalProductId(record.key);
        }}
      >
        {t("copy")}
      </Menu.Item>,
    ];
    const productStatus = record.status;
    switch (productStatus) {
      case "D":
        return [
          <Menu.Item key={2}>
            <EditProductLink id={record.key} />
          </Menu.Item>,
          renderConfirmableButton(t("transfer"), t("confirm-transfer"), () =>
            updateProductStatus([record.key], "R")
          ),
          renderConfirmableButton(
            t("delete"),
            t("confirm-delete"),
            () => removeProduct([record.key]),
            0
          ),
          ...constantOptions,
        ];
      case "R":
        return [
          <Menu.Item key={2}>
            <EditProductLink id={record.key} />
          </Menu.Item>,
          renderConfirmableButton(
            t("publish"),
            t("confirm-publish"),
            () => updateProductStatus([record.key], "P"),
            4
          ),
          renderConfirmableButton(
            t("hide-product"),
            t("confirm-hide"),
            () => updateProductStatus([record.key], "H"),
            6
          ),
          renderConfirmableButton(
            t("delete"),
            t("confirm-delete"),
            () => removeProduct([record.key]),
            0
          ),
          ...constantOptions,
        ];
      case "U":
        return [
          renderConfirmableButton(
            t("restore"),
            t("confirm-restore"),
            () => updateProductStatus([record.key], "D"),
            3
          ),
          renderConfirmableButton(
            t("archive-product"),
            t("confirm-archival"),
            () => updateProductStatus([record.key], "A"),
            3
          ),
          renderConfirmableButton(
            t("delete"),
            t("confirm-delete"),
            () => removeProduct([record.key]),
            0
          ),
          ...constantOptions,
        ];
      case "P":
        return [
          <Menu.Item key={2}>
            <EditProductLink id={record.key} />
          </Menu.Item>,
          renderConfirmableButton(
            t("hide-product"),
            t("confirm-hide"),
            () => updateProductStatus([record.key], "H"),
            6
          ),
          renderConfirmableButton(
            t("cancel-product"),
            t("confirm-withdrawn"),
            () => updateProductStatus([record.key], "U"),
            7
          ),
          ...constantOptions,
        ];
      case "H":
        return [
          <Menu.Item key={2}>
            <EditProductLink id={record.key} />
          </Menu.Item>,
          renderConfirmableButton(
            t("restore"),
            t("confirm-restore"),
            () => updateProductStatus([record.key], "P"),
            9
          ),
          renderConfirmableButton(
            t("cancel-product"),
            t("confirm-withdrawn"),
            () => updateProductStatus([record.key], "U"),
            10
          ),
          renderConfirmableButton(
            t("delete"),
            t("confirm-delete"),
            () => removeProduct([record.key]),
            0
          ),
          ...constantOptions,
        ];
      case "A":
        return [
          renderConfirmableButton(
            t("restore"),
            t("confirm-restore"),
            () => updateProductStatus([record.key], "D", true),
            5
          ),
        ];
      default:
        return null;
    }
  };

  const rowSelection = {
    columnWidth: "3%",
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRowKeys);
    },
    selectedRowKeys: selectedRows,
  };

  const onTableChange = (pagination, filters, sorter) => {
    if (pagination.current !== currentPage) {
      updateCurrentPage(pagination.current);
      setFiltersContext({
        changes: [{ field: "currentPage", value: pagination.current }],
      });
    }
    if (_.isEmpty(sorter) && sortColumn.length > 0) {
      updateSortColumn([]);
      updateCurrentPage(1);
      setFiltersContext({
        changes: [
          { field: "currentPage", value: 1 },
          { field: "sortColumn", value: [] },
        ],
      });
    } else if (!_.isEmpty(sorter)) {
      let sortColumns = sorter.column.sortName;
      if (sorter.order === "descend") {
        sortColumns = sortColumns.map((value) => "-" + value);
      }
      if (!_.isEqual(sortColumns, sortColumn)) {
        updateSortColumn(sortColumns);
        updateCurrentPage(1);
        setFiltersContext({
          changes: [
            { field: "currentPage", value: 1 },
            { field: "sortColumn", value: sortColumns },
          ],
        });
      }
    }
  };

  const renderGroupButton = () => {
    switch (status) {
      case "D":
        return [
          renderConfirmableButton(
            t("transfer"),
            t("confirm-transfer"),
            () => updateProductStatus(selectedRows, "R", true),
            1
          ),
          renderConfirmableButton(
            t("delete-checked"),
            t("confirm-checked"),
            () => {
              removeProduct(selectedRows);
              setSelectedRows(null);
            },
            "remove"
          ),
        ];
      case "R":
        return [
          renderConfirmableButton(
            t("publish"),
            t("confirm-publish"),
            () => updateProductStatus(selectedRows, "P", true),
            2
          ),
          renderConfirmableButton(
            t("hide-product"),
            t("confirm-hide"),
            () => updateProductStatus(selectedRows, "H", true),
            6
          ),
          renderConfirmableButton(
            t("delete-checked"),
            t("confirm-checked"),
            () => {
              removeProduct(selectedRows);
              setSelectedRows(null);
            },
            "remove"
          ),
        ];

      case "P":
        return [
          renderConfirmableButton(
            t("hide-product"),
            t("confirm-hide"),
            () => updateProductStatus(selectedRows, "H", true),
            3
          ),
          renderConfirmableButton(
            t("cancel-product"),
            t("confirm-withdrawn"),
            () => updateProductStatus(selectedRows, "U", true),
            4
          ),
        ];
      case "H":
        return [
          renderConfirmableButton(
            t("restore"),
            t("confirm-restore"),
            () => updateProductStatus(selectedRows, "P", true),
            5
          ),
          renderConfirmableButton(
            t("cancel-product"),
            t("confirm-withdrawn"),
            () => updateProductStatus(selectedRows, "U", true),
            4
          ),
          renderConfirmableButton(
            t("delete-checked"),
            t("confirm-checked"),
            () => {
              removeProduct(selectedRows);
              setSelectedRows(null);
            },
            "remove"
          ),
        ];
      case "U":
        return [
          renderConfirmableButton(
            t("restore"),
            t("confirm-restore"),
            () => updateProductStatus(selectedRows, "D", true),
            4
          ),
          renderConfirmableButton(
            t("archive-product"),
            t("confirm-archival"),
            () => updateProductStatus(selectedRows, "A", true),
            4
          ),
          renderConfirmableButton(
            t("delete-checked"),
            t("confirm-checked"),
            () => {
              removeProduct(selectedRows);
              setSelectedRows(null);
            },
            "remove"
          ),
        ];
      case "A":
        return [
          renderConfirmableButton(
            t("restore"),
            t("confirm-restore"),
            () => updateProductStatus(selectedRows, "D", true),
            5
          ),
        ];
      default:
        return null;
    }
  };

  const getProductsData = (d) => {
    if (!d || !d.general) return null;
    const products = d.general.products.edges.map((e) => e.node);
    return products.map((p) => {
      return {
        key: p.id,
        name: <Tooltip title={p.displayName || p.name}>{trimString(p.displayName || p.name, 20)}</Tooltip>,
        catalogueNumber: p.catalogueNumber,
        status: p.status,
        statusName: p.statusName,
        brandDisplay: p.brandDisplay,
        image: p.attachments.edges.length
          ? p.attachments.edges[0].node.file
          : null,
        category: (
          <Tooltip title={p.categoryFullPath}>{p.mainCategory.displayName || p.mainCategory.name}</Tooltip>
        ),
        priceValue: p.priceValue === null ? `nd` : p.priceValue,
        updatedAt: moment(p.updatedAt).format("YYYY-MM-DD"),
        updatedBy: p.updatedBy === null ? "nn" : p.updatedBy.displayName,
      };
    });
  };

  const handleParamFilter = (e, param, index, limit = null) => {
    updateCurrentPage(1);
    let filters = JSON.parse(JSON.stringify(paramFilters));
    if (!filters[index]) filters[index] = Object();
    if (limit) {
      if (e) {
        if (!filters[index]) filters[index] = Object();
        filters[index].paramId = param.id;
        filters[index].valueType = param.type;
        if (limit === "min") filters[index].minNumberValue = e;
        else filters[index].maxNumberValue = e;
      } else {
        if (limit === "min") delete filters[index].minNumberValue;
        else delete filters[index].maxNumberValue;
        if (!filters[index].minNumberValue && !filters[index].maxNumberValue)
          filters[index] = null;
      }
    } else {
      if ((Array.isArray(e) && !e.length) || !e) {
        filters[index] = null;
      } else {
        filters[index].paramId = param.id;
        filters[index].valueType = param.type;
        if (Array.isArray(e)) {
          filters[index].values = e;
        } else {
          filters[index].textValue = e;
        }
      }
    }
    setParamFilters(filters);
    setFiltersContext({
      changes: [
        { field: "currentPage", value: 1 },
        { field: "paramFilters", value: filters },
      ],
    });
  };

  const renderParamFilter = (param, idx) => {
    const style = { width: "40%" };
    let bottomRow = null;
    const debouncedHandle = debounce(handleParamFilter, 400);
    switch (param.type) {
      case "N":
      case "R":
        bottomRow = (
          <InputGroup compact style={{ display: "flex", width: "30%" }}>
            <InputNumber
              style={style}
              placeholder={t("from")}
              onChange={(e) => debouncedHandle(e, param, idx, "min")}
              min={0}
            />
            <InputNumber
              style={style}
              placeholder={t("to")}
              onChange={(e) => debouncedHandle(e, param, idx, "max")}
              min={0}
            />
          </InputGroup>
        );
        break;
      case "T":
        bottomRow = (
          <Input
            style={style}
            onChange={(e) => debouncedHandle(e.target.value, param, idx)}
            placeholder={t("value")}
            allowClear={true}
          />
        );
        break;
      case "B":
        bottomRow = (
          <Select
            style={style}
            allowClear={true}
            clearIcon={<Icon type="close" />}
            placeholder={t("select")}
            onChange={(e) => handleParamFilter(e, param, idx)}
          >
            <Select.Option key={1}>{t("yes")}</Select.Option>
            <Select.Option key={0}>{t("no")}</Select.Option>
          </Select>
        );
        break;
      case "A":
      case "C":
        bottomRow = (
          <Query
            query={PARAMETER_CHOICES_QUERY}
            variables={{
              id: param.id,
            }}
          >
            {({ data, loading, errors }) => {
              if (!loading) {
                return (
                  <Select
                    style={style}
                    mode={"multiple"}
                    key={idx}
                    allowClear={true}
                    clearIcon={<Icon type="close" />}
                    placeholder={t("select")}
                    onChange={(e) => debouncedHandle(e, param, idx)}
                  >
                    {data.general.parameters.edges[0].node.choices.map(
                      (choice, choice_idx) => {
                        return (
                          <Select.Option key={choice_idx} value={choice}>
                            {choice}
                          </Select.Option>
                        );
                      }
                    )}
                  </Select>
                );
              } else {
                return null;
              }
            }}
          </Query>
        );
        break;
      default:
        return null;
    }
    return (
      <>
        <p style={{ marginRight: "10px", width: "100%" }}>{param.name}</p>
        {bottomRow}
      </>
    );
  };

  const renderParamsFilters = () => {
    if (categoryFilter) {
      return (
        <div>
          <Query
            query={CATEGORY_MAIN_PARAMETERS_QUERY}
            variables={{
              categoryId: categoryFilter,
            }}
          >
            {({ data, loading, error }) => {
              if (!loading && !error) {
                const paramsLength =
                  data.general.categories.edges[0].node.mainParameters.edges
                    .length;
                const paramFilters = data.general.categories.edges[0].node.mainParameters.edges
                  .slice(0, filtersNumber)
                  .map((paramEdge, idx) => {
                    return (
                      <StyledFilterDiv key={idx}>
                        {renderParamFilter(paramEdge.node, idx)}
                      </StyledFilterDiv>
                    );
                  });
                if (paramFilters.length === 0) {
                  return null;
                } else {
                  return (
                    <Collapse
                      defaultActiveKey={["1"]}
                      style={{ marginBottom: "15px" }}
                    >
                      <Panel header={t("filters")}>
                        {paramFilters}
                        <Button
                          disabled={filtersNumber >= paramsLength}
                          onClick={() => {
                            setFiltersNumber(filtersNumber + 5);
                          }}
                        >
                          {t("more-filters")}
                        </Button>
                      </Panel>
                    </Collapse>
                  );
                }
              } else return null;
            }}
          </Query>
        </div>
      );
    }
  };

  const onBrandChange = (e) => {
    updateCurrentPage(1);
    setCategoryFilter(null);
    setBrandFilter(e);
    setParamFilters([]);
    setFiltersContext({
      changes: [
        { field: "currentPage", value: 1 },
        { field: "brandFilter", value: e },
        { field: "categoryFilter", value: null },
        { field: "paramFilters", value: [] },
      ],
    });

    client
      .query({
        query: MAIN_CATEGORY_QUERY,
        variables: {
          brand: e,
        },
      })
      .then((res) => {
        setMainCategories(
          res.data.general.categories.edges.map((obj) => obj.node)
        );
      });
  };

  const clearFilters = () => {
    updateCurrentPage(1);
    setMinPriceFilter(0);
    setMaxPriceFilter(maxPrice);
    setDisplayMinPrice(0);
    setDisplayMaxPrice(maxPrice);
    setBrandFilter(null);
    setCategoryFilter(null);
    setParamFilters([]);
    setSearchState("");
    setSelectedRows([]);
    setFiltersContext({
      clear: true,
    });
    setFiltersContext({
      changes: [
        { field: "activeTab", value: status },
        { field: "search", value: search },
      ],
    });
  };

  const onCategoryChange = (e) => {
    updateCurrentPage(1);
    setCategoryFilter(e);
    setParamFilters([]);
    setFiltersContext({
      changes: [
        { field: "currentPage", value: 1 },
        { field: "categoryFilter", value: e },
        { field: "paramFilters", value: [] },
      ],
    });
  };

  const renderCategoryFilter = () => {
    return (
      <Row
        type="flex"
        align="middle"
        style={{ marginLeft: "10px", marginBottom: "15px" }}
      >
        <Icon type="control" style={{ fontSize: "30px" }} />
        <span style={{ marginRight: "15px", marginLeft: "5px" }}>
          {t("price") + ":"}
        </span>
        <InputNumber
          value={displayMinPrice}
          style={{ width: "75px" }}
          onChange={(value) => {
            if (value && value < maxPriceFilter) {
              setDisplayMinPrice(value);
              setMinPriceFilter(value);
              setFiltersContext({
                changes: [{ field: "minPriceFilter", value }],
              });
            } else {
              setDisplayMinPrice(0);
              setMinPriceFilter(0);
              setFiltersContext({
                changes: [{ field: "minPriceFilter", value: 0 }],
              });
            }
          }}
        />
        <Slider
          range
          defaultValue={[displayMinPrice, displayMaxPrice]}
          style={{ width: "140px", margin: "0 15px" }}
          min={0}
          max={maxPrice}
          onAfterChange={(value) => {
            setMinPriceFilter(value[0]);
            setMaxPriceFilter(value[1]);
            setFiltersContext({
              changes: [
                { field: "minPriceFilter", value: value[0] },
                { field: "maxPriceFilter", value: value[1] },
              ],
            });
          }}
          onChange={(value) => {
            setDisplayMinPrice(value[0]);
            setDisplayMaxPrice(value[1]);
          }}
          value={[displayMinPrice, displayMaxPrice]}
        />
        <InputNumber
          value={displayMaxPrice}
          style={{ width: "75px" }}
          onChange={(value) => {
            if (value && value > minPriceFilter) {
              setDisplayMaxPrice(value);
              setMaxPriceFilter(value);
              setFiltersContext({
                changes: [{ field: "maxPriceFilter", value }],
              });
            } else if (value && value < minPriceFilter) {
              setDisplayMaxPrice(maxPrice);
            } else {
              setDisplayMaxPrice(0);
              setMaxPriceFilter(0);
              setFiltersContext({
                changes: [{ field: "maxPriceFilter", value: 0 }],
              });
            }
          }}
        />
        <Divider
          type="vertical"
          style={{ marginRight: "0", marginLeft: "15px" }}
        />
        <Select
          placeholder={t("brand")}
          allowClear={true}
          defaultValue={brandFilter}
          value={brandFilter}
          clearIcon={<Icon type="close" />}
          onChange={(e) => {
            onBrandChange(e);
          }}
          style={{
            width: "200px",
            marginLeft: "15px",
          }}
        >
          {brands.map((obj, idx) => {
            return (
              <Select.Option value={obj[0]} key={idx}>
                {obj[1]}
              </Select.Option>
            );
          })}
        </Select>
        <Divider
          type="vertical"
          style={{ marginRight: "0", marginLeft: "15px" }}
        />
        <Select
          placeholder={t("category")}
          allowClear={true}
          defaultValue={categoryFilter}
          disabled={!brandFilter}
          value={categoryFilter}
          clearIcon={<Icon type="close" />}
          onChange={(e) => {
            onCategoryChange(e);
          }}
          style={{
            width: "200px",
            marginLeft: "15px",
          }}
        >
          {mainCategories.map((obj, idx) => {
            return (
              <Select.Option value={obj.id} key={idx}>
                {obj.displayName || obj.name}
              </Select.Option>
            );
          })}
        </Select>
        <Divider
          type="vertical"
          style={{ marginRight: "15px", marginLeft: "15px" }}
        />
        <Button onClick={() => clearFilters()}>{t("clearFilters")}</Button>
      </Row>
    );
  };
  return (
    <>
      {renderCategoryFilter()}
      {renderParamsFilters()}
      <Query
        query={PRODUCTS_TABLE_QUERY}
        fetchPolicy={"cache-and-network"}
        onCompleted={(data) => {
          if (data) {
            updateTotalRecords(data.general.products.totalCount);
          }
        }}
        variables={{
          status,
          pageSize,
          offset,
          sortColumn: sortColumn ? sortColumn : null,
          search,
          categoryId: categoryFilter,
          brand: brandFilter,
          minPrice: minPriceFilter,
          maxPrice: maxPriceFilter,
          filters: paramFilters.filter((filter) => filter),
        }}
      >
        {({ data, loading, errors, refetch }) => {
          return (
            <Table
              columns={getColumns()}
              rowSelection={rowSelection}
              loading={loading}
              onChange={onTableChange}
              pagination={{
                pageSize,
                total: totalRecords,
                current: currentPage,
              }}
              footer={(currentPageData) => {
                return (
                  status !== "" && (
                    <Dropdown
                      onClick={() => {
                        if ("group" === activeDropdown)
                          updateActiveDropdown(null);
                        else updateActiveDropdown("group");
                      }}
                      trigger={["click"]}
                      onVisibleChange={(visible) =>
                        !visible &&
                        "group" === activeDropdown &&
                        updateActiveDropdown(null)
                      }
                      visible={activeDropdown === "group"}
                      overlay={<Menu>{renderGroupButton()}</Menu>}
                    >
                      <Button>{t("group-actions")}</Button>
                    </Dropdown>
                  )
                );
              }}
              dataSource={getProductsData(data)}
            />
          );
        }}
      </Query>
      <DuplicateProductModal
        variables={{
          status,
          pageSize,
          offset,
          sortColumn: sortColumn ? sortColumn : null,
          search,
          categoryId: categoryFilter,
          brand: brandFilter,
          minPrice: minPriceFilter,
          maxPrice: maxPriceFilter,
          filters: paramFilters.filter((filter) => filter),
        }}
        id={duplicateModalProductId}
        nameInitial={duplicateModalText}
        catalogueNumberInitial={duplicateModalCatalogueNumber}
        showModal={showDuplicateModal}
        setShowModal={updateShowDuplicateModal}
      />
    </>
  );
};

ProductsTableComponent.defaultProps = {};

ProductsTableComponent.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      category: PropTypes.string,
      number: PropTypes.number,
      price: PropTypes.string,
      lastModified: PropTypes.instanceOf(Date),
      modifiedBy: PropTypes.string,
      id: PropTypes.string,
    })
  ),
};
const ProductsTable = withTranslation("products")(ProductsTableComponent);
export default ProductsTable;
