import React, {useEffect,useState} from "react";
import {Input, Button, Select, message, Switch, InputNumber, Icon} from 'antd'
import client from "../../config/apollo/client";
import PARAMETER_PRODUCT_MUTATION from "../../gql/mutations/BULK_EDITOR/PARAMETER_PRODUCT_MUTATION";
import PARAMETER_MUTATION from "../../gql/mutations/PARAMETER_MUTATION";

const {Option} = Select
const BulkTableEditorForm = ({parameter, product}) =>{
    const [editableParameterProduct, setEditableParameterProduct] = useState(false)
    const [parameterValue, setParameterValue] = useState("")
    const [newParameterValue, setNewParameterValue] = useState(false)
    const [parameterValueTo, setParameterValueTo] = useState("")
    const [newParameterValueTo, setNewParameterValueTo] = useState("")
    const [parameterType, setParameterType] = useState(false)
    const [parameterChoices, setParameterChoices] = useState([])
    const [parameterUnit, setParameterUnit] = useState("")
    const [runEdit, setRunEdit] = useState(false)

    useEffect(()=>{
        setParameterType(parameter.type)
        setParameterChoices(parameter.choices)
        setParameterUnit(parameter.unit)
        product.productparameterSet.edges.map((product_parameter)=>{
            if(product_parameter.node.parameter.id===parameter.id){
                setEditableParameterProduct(product_parameter.node)
                switch(parameter.type){
                    case "T":
                        setParameterValue(product_parameter.node.textValue)
                        setNewParameterValue(product_parameter.node.textValue)
                        break;
                    case "L":
                        setParameterValue(product_parameter.node.textValue)
                        setNewParameterValue(product_parameter.node.textValue)
                        break;
                    case "A":
                        setParameterValue(product_parameter.node.arrayValue)
                        setNewParameterValue(product_parameter.node.arrayValue)
                        break;
                    case "C":
                        setParameterValue(product_parameter.node.textValue)
                        setNewParameterValue(product_parameter.node.textValue)
                        break;
                    case "N":
                        setParameterValue(product_parameter.node.numberValue)
                        setNewParameterValue(product_parameter.node.numberValue)
                        break;
                    case "B":
                        setParameterValue(product_parameter.node.numberValue)
                        setNewParameterValue(product_parameter.node.numberValue)
                        break;
                    case "R":
                        setParameterValue(product_parameter.node.numberValue)
                        setNewParameterValue(product_parameter.node.numberValue)
                        setParameterValueTo(product_parameter.node.numberValueTo)
                        setNewParameterValueTo(product_parameter.node.numberValueTo)
                        break;

                }
            }
        })
    },[parameter, product])

    const changeNewValueHandler = (e) =>{
        setNewParameterValue(e.target.value)
    }

    const saveButtonHandler = () =>{
        let mutateVariables = {
            "productId":product.id,
            "parameterId": parameter.id,
            "parameterProductId": false,
            "newValue": newParameterValue,
            "newValueTo": newParameterValueTo
        }
        if(parameter.type==="A"){
            mutateVariables['newValueArray'] = newParameterValue
            mutateVariables['newValue'] = "";
        }
        if(editableParameterProduct){
            mutateVariables["parameterProductId"] = editableParameterProduct.id
        }
        if(newParameterValue!=parameterValue || newParameterValueTo!=parameterValueTo){
            client.mutate({
                mutation: PARAMETER_PRODUCT_MUTATION,
                variables:{"input":mutateVariables}
            }).then(
                (res)=>{
                    if(res.data.parameterProductMutation.edited){
                        setParameterValue(newParameterValue)
                        setParameterValueTo(newParameterValueTo)
                        message.success("Zmodyfikowano wartość")
                        setRunEdit(false)
                    }else{
                        message.error("Nie zmodyfikowano wartości")
                        setRunEdit(false)
                    }
                }
            )
        }else{
            message.warning("Nie wprowadzono żadnych zmian")
            setRunEdit(false)
        }
    }

    if(runEdit){
        switch(parameter.type){
            case "T"|| "L":
                return(
                    <div style={{display: "flex"}}>
                        <Input addonAfter={parameterUnit} onChange={changeNewValueHandler} defaultValue={parameterValue} style={{maxWidth: "50%"}}/>
                        <Button onClick={saveButtonHandler}><Icon type="save" /></Button>
                    </div>
                )
                break;
            case "N":
                return(
                    <div style={{display: "flex"}}>
                        <InputNumber addonAfter={parameterUnit} step={0.00001} onChange={(e)=>setNewParameterValue(e)} defaultValue={parameterValue} style={{minWidth: "70%"}}/>
                        <Button onClick={saveButtonHandler}><Icon type="save" /></Button>
                    </div>
                )
            case "B":
                return(
                    <div style={{display: "flex"}}>
                        <Switch onChange={(e)=>{
                            if(e){
                                return setNewParameterValue(1)
                            }else{
                                return setNewParameterValue(0)
                            }
                            }} defaultChecked={parameterValue} />
                        <Button onClick={saveButtonHandler}><Icon type="save" /></Button>
                    </div>
                )
                break;
            case "C":
                return(
                    <div style={{display: "flex"}}>
                        <Select  style={{minWidth: "70%"}} onChange={(e)=>setNewParameterValue(e)} defaultValue={parameterValue}>
                            {parameterChoices.map(
                                (choice)=><Option key={choice} value={choice}>{choice}</Option>
                            )}
                        </Select>
                        <Button onClick={saveButtonHandler}><Icon type="save" /></Button>
                    </div>
                )
                break;
            case "A":
                return(
                    <div style={{display: "flex"}}>
                        <Select  style={{minWidth: "70%"}} onChange={(e)=>setNewParameterValue(e)} defaultValue={parameterValue} mode="multiple">
                            {parameterChoices.map(
                                (choice)=><Option key={choice} value={choice}>{choice}</Option>
                            )}
                        </Select>
                        <Button onClick={saveButtonHandler}><Icon type="save" /></Button>
                    </div>
                )
                break;
            case "R":
                return(
                    <div style={{display: "flex", alignItems: "center"}}>
                        <InputNumber onChange={(e)=>setNewParameterValue(e)} defaultValue={parameterValue} style={{maxWidth: "50%"}}/>
                        <span>do</span>
                        <InputNumber onChange={(e)=>setNewParameterValueTo(e)} defaultValue={parameterValueTo} style={{maxWidth: "50%"}}/>
                        <Button onClick={saveButtonHandler}><Icon type="save" /></Button>
                    </div>
                )
                break;
        }
    }else{
        switch(parameter.type){
            case "B":
                return(
                    <div onClick={()=>setRunEdit(true)}>
                        {parameterValue? <>TAK</>: <>-</>}
                    </div>
                )
                break;
            default:
                return(
                    <div onClick={()=>setRunEdit(true)}>
                        {parameterValue? <>{parameterValue.toString()} {parameterUnit}</>: <>-</>} {parameterValueTo&& <> do {parameterValueTo} {parameterUnit}</>}
                    </div>
                )
                break;

        }
    }
}
export default BulkTableEditorForm