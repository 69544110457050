export default {
  "before": "Przed publikacją",
  "ready": "Gotowe do publikacji",
  "out": "Wycofane",
  "current": "Obecne na rynku",
  "hide": "Ukryte",
  "archival": "Archiwalne",
  "updatedAt": "Ostatnia modyfikacja",
  "modyfiedBy": "Modyfikowane przez",
  "products": "Produkty",
  "add-product": "+ dodaj produkt",
  "mass-edit-products": "Masowa edycja produktów",
  "addValidDate": 'Daty ważności cen',
  "employees": "Pracownicy i uprawnienia",
  "employeesList": "Lista pracowników",
  "addEmployee": "+ dodaj pracownika",
  "dataExport": "Eksport danych",
  "templates": "Szablony",
  "fileExport": "Eksportuj plik",
  "createTemplate": "Stwórz szablon",
  "priceImport": "Import cen",
  "importProduct": "Importuj produkty",
  "productImports": "Import produktów",
  "importsHistory": "Historia importów",
  "importProductsHistory": "Historia importów produktów",
  "importPrice": "Importuj ceny",
  "searchProduct": "wyszukaj produkt",
  "log-out": "Wyloguj",
  "brand": "Marka",
  "category": "Kategoria",
  "status": "Status",
  "name": "Nazwa",
  "catalougeNumber": "Numer katalogowy",
  "price": "Cena",
  "more": "Więcej",
  "edit": "Edytuj produkt",
  "hide-product": "Ukryj produkt",
  "transfer": "Przekaż do publikacji",
  "cancel-product": "Wycofaj produkt",
  "delete": "Usuń produkt",
  "copy": "Powiel produkt",
  "publish": "Opublikuj produkt",
  "restore": "Przywróć produkt",
  "edit-product": "Edytuj produkt",
  "archive-product": "Zarchiwizuj produkt",
  "yes": "Tak",
  "no": "Nie",
  "confirm-delete": "Czy na pewno chcesz usunąć ten produkt?",
  "confirm-publish": "Czy na pewno chcesz opublikować ten produkt?",
  "confirm-hide": "Czy na pewno chcesz ukryć ten produkt?",
  "confirm-withdrawn": "Czy na pewno chcesz wycofać ten produkt?",
  "confirm-transfer": "Czy na pewno chcesz przenieść ten produkt do publikacji?",
  "confirm-restore": "Czy na pewno chcesz przywrócić ten produkt?",
  "confirm-archival": "Czy na pewno chcesz zarchiwizować ten produkt",
  "-copy": "-kopia",
  "delete-checked": "Usuń wszystkie zaznaczone",
  "confirm-checked": "Czy na pewno chcesz usunąć wszytskie zaznaczone?",
  "group-actions": "Akcje grupowe",
  "unknownNumber": "Nieznany numer",
  "giveNumber": "Podaj numer",
  "catalogueNumberPlaceholder": "Podaj numer katalogowy produktu",
  "verify": "Weryfikuj",
  "noProduct": "Brak produktu",
  "noQuantity": "Podaj ilość",
  "quantityPlaceholder": "Podaj ilość",
  "removePosition": "Usuń pozycję",
  "moveUp": "Przesuń pozycję w górę",
  "addUnder": "Dodaj pozycję pod",
  "value": "Wpisz wartość",
  "select": "Wybierz opcję",
  "from": "od",
  "to": "do",
  "filters": "Filtry",
  "all": "Wszystkie",
  "more-filters": "Więcej filtrów",
  "validationErr-length" : "Opis za długi!",
  "clearFilters" : "Wyczyść filtry",
  "promotions": "Promocje",
  "categoriesAndSubcategories": "Kategorie i podkategorie",
  "productSelectionSystem": "System doboru produktów",
}