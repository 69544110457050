import {Button, Input, InputNumber, Switch, Select, Icon, message} from 'antd'
import React, {useContext, useState} from 'react'
import {BulkProductEditorContext} from "../../logic/context/BulkProductEditorContext/BulkProductEditorContext";
import PARAMETER_PRODUCTS_MUTATION from "../../gql/mutations/BULK_EDITOR/PARAMETER_PRODUCTS_MUTATION";
import client from "../../config/apollo/client";

const {Option} = Select
const BulkParametersEditorForm = ({parameter}) =>{
    const {editProducts, setReloadMainTable} = useContext(BulkProductEditorContext)
    const [newParameterValue, setNewParameterValue] = useState(false)
    const [newParameterValueTo, setNewParameterValueTo] = useState("")

    const changeNewValueHandler = (e) =>{
        setNewParameterValue(e.target.value)
    }
    const editForm = () =>{
        switch(parameter.node.type){
            case "T":
                return <Input onChange={changeNewValueHandler} />
            case "L":
                return <Input onChange={changeNewValueHandler} />
            case "N":
                return <InputNumber onChange={(e)=>setNewParameterValue(e)} style={{minWidth:"10em"}} step="0.00001" />
            case "B":
                return <Switch  onChange={(e)=>{
                    if(e){
                        return setNewParameterValue(1)
                    }else{
                        return setNewParameterValue(0)
                    }
                }} />
            case "C":
                return <Select style={{minWidth:"10em"}} onChange={(e)=>setNewParameterValue(e)}>
                    {parameter.node.choices.map((option)=><Option value={option}>{option}</Option>)}
                </Select>
            case "A":
                return <Select  style={{minWidth: "70%"}} onChange={(e)=>setNewParameterValue(e)} mode="multiple">
                    {parameter.node.choices.map(
                        (choice)=><Option key={choice} value={choice}>{choice}</Option>
                    )}
                </Select>
            case "R":
                return <div style={{display:"flex", alignItems:"center"}}><InputNumber onChange={(e)=>setNewParameterValue(e)} style={{maxWidth: "50%"}}/>
                    <span>do</span>
                    <InputNumber onChange={(e)=>setNewParameterValueTo(e)} style={{maxWidth: "50%"}}/></div>
        }
    }

    const saveButtonHandler = () => {
        let mutateVariables = {
            "productIds": editProducts,
            "parameterId": parameter.node.id,
            "newValue": newParameterValue,
            "newValueTo": newParameterValueTo,
        }
        if(parameter.node.type==="A"){
            mutateVariables['newValueArray'] = newParameterValue
            mutateVariables['newValue'] = "";
        }
        message.loading('Trwa modyfikowanie parametrów produktów...')
        client.mutate({
            mutation: PARAMETER_PRODUCTS_MUTATION,
            variables:{"input": mutateVariables}
        }).then((res)=>{
            if(res.data.parameterProductsMutation.edited){
                message.success("Parametry zmodyfikowane")
                setReloadMainTable(true)
            }else{
                message.error("Nie zmodyfikowano parametrów")
            }
        })
    }

    return(
        <div style={{display: "flex", justifyContent:"flex-end"}}>
            {editForm()}
            <Button onClick={saveButtonHandler}><Icon type="save" /></Button>
        </div>
    )
}

export default BulkParametersEditorForm