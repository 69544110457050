import gql from 'graphql-tag'

const GET_DRAFT_OFFER_LIST = gql`
query{
  general{
    draftOffer{
      edges{
        node{
          id,
          name,
          brand,
          status
        }
      }
    }
  }
}
`

export default  GET_DRAFT_OFFER_LIST