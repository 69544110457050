import gql from 'graphql-tag'

const DRAFT_OFFER_MUTATION = gql`
mutation($input:DraftOfferMutationInput!){
  draftOfferMutation(input:$input){
    created
  }
}
`

export default DRAFT_OFFER_MUTATION;