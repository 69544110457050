import gql from 'graphql-tag';

const PARAMETER_PRODUCT_MUTATION = gql`
mutation($input:ParameterProductMutationInput!){
  parameterProductMutation(input:$input){
    edited
  }
}
`

export default PARAMETER_PRODUCT_MUTATION