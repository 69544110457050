import React, {useState, useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {withTranslation} from "react-i18next";

import {get} from "lodash";
import {message} from "antd";

import Table from "./Table";
import DefaultCategoryImage from "./DefaultCategoryImage";


import uppercaseFirstLetter from "../../utils/uppercaseFirstLetter";

import client from "../../config/apollo/client";
import MAIN_CATEGORY_QUERY from "../../gql/queries/MAIN_CATEGORY_QUERY";
import SUBCATEGORY_QUERY from "../../gql/queries/SUBCATEGORY_QUERY";
import CHANGE_CATEGORIES_ORDER_MUTATION from "../../gql/mutations/CHANGE_CATEGORIES_ORDER_MUTATION";

const Categories = ({t}) => {
  const [ categories, setCategories ] = useState([]);
  const {categoryId, brand: initialBrand} = useParams();
  const history = useHistory()
  const [ brand, setBrand ] = useState(initialBrand || 'V');
  const onBrandChange = value => setBrand(value)

  const setErrorMsg = (errorType) =>
    uppercaseFirstLetter(
      t(`something-went-wrong-while-${errorType}-categories`)
    );

  const fetchMainCategories = () => {
    client
      .query({
        query: MAIN_CATEGORY_QUERY,
        fetchPolicy: "network-only",
        variables: {
          brand,
        },
      })
      .then(({data}) => {
        const categories = get(data, "general.categories.edges");
        setCategories(categories);
      })
      .catch(() => message.error(setErrorMsg("fetching")));
  };

  const fetchSubCategories = (id) => {
    client
      .query({
        query: SUBCATEGORY_QUERY,
        fetchPolicy: "network-only",
        variables: {
          brand,
          parent: id,
        },
      })
      .then(({data}) => {
        const subcategories = get(data, "general.categories.edges");
        setCategories(subcategories);
      })
      .catch(() => message.error(setErrorMsg("fetching")));
  };

  const updateTableDataOrder = (orderedCategories) => {
    const orderedCategoryId = orderedCategories.map(({ node }) => node.id);
    client
      .mutate({
        mutation: CHANGE_CATEGORIES_ORDER_MUTATION,
        variables: {
          input: {
            ids: orderedCategoryId,
          },
        },
      })
      .then(() => setCategories(orderedCategories))
      .catch(() => message.error(setErrorMsg("reordering")));
  };

  const updateTableDataContent = () => {
    if (categoryId) {
      fetchSubCategories(categoryId);
    } else {
      fetchMainCategories();
    }
  };

  useEffect(() => {
    updateTableDataContent();
    const {pathname} = history.location
    const [ brandFromURL ] = pathname.match(/(?<=(\/))(S|V)$/) || []
    if (brandFromURL !== brand) {
      history.replace(pathname.replace(`/${brandFromURL}`, `/${brand}`))
    }
    if (!brandFromURL && !categoryId) {
      history.replace(`${pathname}/V`)
    }
  }, [ brand, categoryId ]);

  return (
    <>
            <DefaultCategoryImage onBrandChange={onBrandChange} brand={brand} showBrandSelect={!categoryId}/>
      <Table
        brand={brand}
        categories={categories}
        updateTableData={updateTableDataContent}
        updateCategoriesOrder={updateTableDataOrder}
      />
    </>
  );
};

const TranslatedCategories = withTranslation("categoriesAndSubcategories")(
  Categories
);

export default TranslatedCategories;
