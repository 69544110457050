import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "antd/dist/antd.css";
import { Upload, message, Button, Icon } from "antd";

import formatFileName from "../../../../utils/formatFileName";
import uppercaseFirstLetter from "../../../../utils/uppercaseFirstLetter";

import client from "../../../../config/apollo/client";
import IMPORT_CATEGORY_IMAGE_MUTATION from "../../../../gql/mutations/IMPORT_CATEGORY_IMAGE_MUTATION";
import DELETE_CATEGORY_IMAGE_MUTATION from "../../../../gql/mutations/DELETE_CATEGORY_IMAGE_MUTATION";

import { withTranslation } from "react-i18next";

const UploadBtn = ({ record, myFileList, t }) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    const convertedFileListNames = myFileList.map((fileData) => {
      if (fileData.name) {
        return {
          ...fileData,
          name: formatFileName(fileData.name),
          url: `${process.env.REACT_APP_URL}${fileData.name}`
        };
      }
      return fileData;
    });

    setFileList(convertedFileListNames);
  }, [myFileList, record]);

  const successMsg = uppercaseFirstLetter(t("msg-success"));
  const errorMsg = uppercaseFirstLetter(t("msg-error"));

  const uploadFile = ({ file }) => {
    client
      .mutate({
        mutation: IMPORT_CATEGORY_IMAGE_MUTATION,
        variables: {
          input: {
            file,
            categoryId: record.id,
          },
        },
      })
      .then(() => {
        const newArrItem = {
          uid: record.id,
          name: formatFileName(file.name),
          status: "done",
          url: `${process.env.REACT_APP_URL}/media/${file.name}`
        };
        setFileList([newArrItem]);
        message.success(successMsg);
      })
      .catch(() => {
        message.error(errorMsg);
      });
  };

  const removeFile = (fileList) => {
    client
      .mutate({
        mutation: DELETE_CATEGORY_IMAGE_MUTATION,
        variables: {
          input: {
            categoryId: fileList.uid,
          },
        },
      })
      .then(() => {
        message.success(successMsg);
        setFileList([]);
      })
      .catch(() => {
        message.error(errorMsg);
      });
  };

  return (
    <>
      <Upload
        multiple={false}
        customRequest={uploadFile}
        onRemove={removeFile}
        fileList={fileList}
      >
        <Button>
          <Icon type="upload" /> {uppercaseFirstLetter(t("uploadImage"))}
        </Button>
      </Upload>
    </>
  );
};

UploadBtn.propTypes = {
  record: PropTypes.object,
  myFileList: PropTypes.array,
};

const TranslatedUploadBtn = withTranslation("categoriesAndSubcategories")(
  UploadBtn
);

export default TranslatedUploadBtn;
