import React, { useState, useEffect, useContext } from "react";
import {
    Input,
    Table,
    Collapse,
    Checkbox
} from "antd";
import {BulkProductEditorContext} from "../../logic/context/BulkProductEditorContext/BulkProductEditorContext";
import BulkTableEditorForm from "../BulkTableEditorForm/BulkTableEditorForm";

const BulkProductsEditorTable = (props) => {
    const [tableData, setTableData] = useState([])
    const [selectAllProducts, setSelectAllProducts] = useState(false)
    const [indeterminateCheckbox, setIndeterminateCheckbox] = useState(false)
    const {editProducts, setEditProducts, editCategory, setEditCategory, categoryParameters, selectedParameters} = useContext(BulkProductEditorContext)
    const [tableColumns, setTableColumns] = useState([])
    const [columnSticky, setColumnSticky] = useState(false)
    const columns = [
        {
            title: "Nazwa",
            dataIndex: "name",
            width: 200,
            key: "name",
            fixed: columnSticky,
        },
        {
            title: "Kategoria",
            dataIndex: "category",
            width:200,
            key: "category",
            fixed: columnSticky,
        },
        {
            title: "Numer katalogowy",
            dataIndex: "catalogueNumber",
            width:150,
            key: "catalogueNumber",
            fixed: columnSticky,
        },
    ]
    useEffect(()=>{
        let tmp_table_data = []
        for(const id in props.tableData){
            tmp_table_data.push(
                {
                    key: props.tableData[id].id,
                    category: props.tableData[id].mainCategory.name,
                    ...props.tableData[id],
                }
            )
        }
        setTableData(tmp_table_data)

    },[props.tableData])

    useEffect(()=>{
        if(selectAllProducts){
            let tmp_selected_products = [...editProducts]
            for(const id in tableData){
                tmp_selected_products.push(tableData[id].id)
            }
            setEditProducts(tmp_selected_products)
        }else{
            setEditProducts([])
            setIndeterminateCheckbox(false)
        }
    }, [selectAllProducts])

    useEffect(()=>{
        let tmp_columns = []
        selectedParameters.map((parameter)=>{
            tmp_columns.push({
                title: parameter.node.name,
                dataIndex:parameter.node.id,
                key: parameter.node.id,
                width:400,
                render: (_,record)=><BulkTableEditorForm parameter={parameter.node} product={record}  />
            })
        })
        if(tmp_columns.length>2){
            setColumnSticky("left")
        }else{
            setColumnSticky(false)
        }
        setTableColumns(tmp_columns)
    },[selectedParameters, props.tableData])


    const rowSelection = {
        columnTitle:<Checkbox indeterminate={indeterminateCheckbox} onChange={(e)=>setSelectAllProducts(e.target.checked)} />,
        selectedRowKeys: editProducts,
        onChange: (selectedRowKeys, selectedRows) => {
            setEditProducts(selectedRowKeys)
        },
        onSelect: (record, selected, selectedRows, nativeEvent)=>{
            if(!selected && selectAllProducts){
                setIndeterminateCheckbox(true)
            }
        },
        getCheckboxProps: record =>({
            checked: editProducts.indexOf(record.id)>-1
        })
    };

    return(
        <div>
            <Table rowSelection={rowSelection} bordered={true} scroll={{ x: (tableColumns.length*400) }} columns={[...columns, ...tableColumns]} dataSource={tableData}/>
        </div>
    )
}
export default BulkProductsEditorTable;
