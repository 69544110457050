import React, {createContext, useState} from 'react'

export const ProductSelectionSystemContext = createContext({
    selectedProducts: [],
    setSelectedProducts: ()=>null,
    brand: null,
    setBrand: ()=> null,
    offerName:"",
    setOfferName:()=>null,
    editedOfferId: false,
    setEditedOfferId: ()=>null,
    offerStatus: null,
    setOfferStatus:()=>null
})

export const ProductSelectionSystemProvider = ({children}) =>{

    const [selectedProducts, setSelectedProducts] = useState([])
    const [brand, setBrand] = useState(null)
    const [offerName, setOfferName] = useState("")
    const [editedOfferId, setEditedOfferId] = useState(false)
    const [offerStatus, setOfferStatus] = useState("DRAFT")

    const value= {
        selectedProducts,
        setSelectedProducts,
        brand,
        setBrand,
        offerName,
        setOfferName,
        editedOfferId,
        setEditedOfferId,
        offerStatus,
        setOfferStatus
    }

    return <ProductSelectionSystemContext.Provider value={value}>{children}</ProductSelectionSystemContext.Provider>
}