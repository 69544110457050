import auth from './auth';
import products from './products';
import addProduct from './addProduct';
import documents from './documents';
import translationButton from './translationButton'
import employees from './employees'
import dataExport from './dataExport'
import priceImport from './priceImport'
import steps from './steps';
import prices from './prices'
import passwordReset from "./passwordReset";
import promotions from './promotions.js';
import categoriesAndSubcategories from './categoriesAndSubcategories';
import productsSelectionSystem from './productsSelectionSystem'

export default {
  auth,
  products,
  addProduct,
  documents,
  translationButton,
  employees,
  dataExport,
  priceImport,
  steps,
  prices,
  passwordReset,
  promotions,
  categoriesAndSubcategories,
  productsSelectionSystem
};

